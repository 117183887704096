import flatten from 'flat';
import resultScaleMapping from '@/data/resultScaleMapping.json';
import _ from 'lodash';

export const unflatten = flatten.unflatten;

export function deepOmit(data, keys) {
  function omitFn(value) {
    if (value && typeof value === 'object') {
      keys.forEach((key) => {
        // eslint-disable-next-line no-param-reassign
        delete value[key];
      });
    }
  }

  return _.cloneDeepWith(data, omitFn);
}

// flatten the data and remove graphql created keys with __typename
export function stripAndFlatten(data) {
  if (!data || !data.length) return null;
  const keysToDelete = Object.keys(flatten(data[0])).filter((key) =>
    key.includes('__typename'),
  );
  return data.map(flatten).map((item, i) => {
    keysToDelete.forEach((keyToDelete) => {
      // eslint-disable-next-line no-param-reassign
      delete item[keyToDelete];
    });
    // eslint-disable-next-line no-param-reassign
    item.__originalData = data[i];
    // Object.keys(item).forEach((key) => {
    //   if (item[key] === null) {
    //     item[key] = '-';
    //   }
    // });

    return item;
  });
}

export const findAndReplaceObject = (input, needle, replacement) =>
  Object.fromEntries(
    Object.entries(input).map(([key, entry]) => {
      if (entry === needle) {
        return [key, replacement];
      }
      if (Object.prototype.toString.call(entry) === '[object Object]') {
        return [key, findAndReplaceObject(entry, needle, replacement)];
      }
      return [key, entry];
    }),
  );

export function defaultFiller(data, fillers) {
  return data.map((item) => {
    Object.keys(item).forEach((key) => {
      if (item[key] === null && fillers[key]) {
        // eslint-disable-next-line no-param-reassign
        item[key] = fillers[key];
      }
    });
    return item;
  });
}

export function calculateScaleMapping(value, type) {
  if (!resultScaleMapping[type] || value === null) return 'none';
  return (
    5 - resultScaleMapping[type].filter((result) => value >= result).length
  );
}

export function scaleMappingExists(type) {
  return resultScaleMapping[type];
}

const errorsMap = {
  ExpiredCodeException: 'Je code is verlopen, vraag een nieuwe code aan',
  CodeMismatchException: 'De code lijkt niet te kloppen',
  UsernameExistsException: 'Gebruikersnaam is al in gebruik.',
  UserNotConfirmedException: 'Het e-mailadres is nog niet gevalideerd.',
  CannotScanMultipleTimesPerDayException:
    'Je kunt maar één keer per dag scannen, probeer het morgen weer!',
  SavingCampaignHasNotStartedException: 'Deze campagne is nog niet begonnen',
  SavingCampaignScanEndDateException: 'Deze campagne is afgelopen.',
  InvalidConfirmationCodeException: 'De code is onjuist',
  NotFoundError: 'Deze gebruiker is niet gevonden',
  NotAuthorizedException: 'Incorrecte gebruikersnaam of wachtwoord',
  UserNotFoundException: 'Gebruikersnaam of wachtwoord incorrect',
  InvalidCredentialsException: 'Incorrecte gebruikersnaam of wachtwoord',
  UserDoesNotHaveEnoughPointsException:
    'Je hebt niet genoeg punten om deze coupon in te wisselen.',
  NoActiveOrganisationFound:
    'Er is geen actieve organisatie gevonden voor deze gebruiker',
};

export function getExceptionName(e) {
  return e?.graphQLErrors?.[0]?.extensions?.exception?.name;
}

export function getExceptionResponse(e) {
  return e?.graphQLErrors[0]?.extensions?.exception?.response;
}

export function parseError(exceptionName) {
  if (errorsMap[exceptionName]) return errorsMap[exceptionName];
  return null;
}

export function hasGraphQLError(e) {
  if (e.graphQLErrors && e.graphQLErrors.length) {
    const exceptionName = getExceptionName(e);

    if (exceptionName === 'TypeError') {
      return e.graphQLErrors[0]?.message;
    }

    // specific error parsing START

    if (
      exceptionName === 'BadRequestException' &&
      e.graphQLErrors.find(({ extensions }) =>
        extensions.exception?.response?.message?.includes(
          'Username already exists',
        ),
      )
    ) {
      return 'UsernameExistsException';
    }

    if (exceptionName === 'TemporaryPasswordInUseException') {
      return 'TemporaryPasswordInUseException';
    }

    // specific error parsing END

    if (exceptionName === 'BadRequestException') {
      return (
        e.response.errors
          .map((e) => e.extensions?.exception?.response?.message)
          ?.join(', ') ?? e.response.errors?.map?.((e) => e.message)?.join(', ')
      );
    }

    if (parseError(exceptionName)) return parseError(exceptionName);

    if (e.graphQLErrors[0]?.message) {
      return [exceptionName, e.graphQLErrors[0]?.message]
        .filter(Boolean)
        .join(': ');
    }

    return e.message;
  }

  return String(e.message).replace('GraphQL error:', '');
}

export function getParameterByName(name, url = window.location.href) {
  const cleanName = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${cleanName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
