export function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join(''),
  );
  return JSON.parse(jsonPayload);
}

export function getRawToken() {
  if (typeof localStorage !== 'undefined') {
    const token = localStorage.getItem('apollo-token');
    return token;
  }
  return null;
}

export function getToken() {
  const rawToken = getRawToken();
  return rawToken && parseJwt(rawToken);
}

export function getOrganisations() {
  const token = getToken();
  if (!token.organisations) return null;
  return Object.values(token.organisations);
}

export function getResourceType() {
  const token = getToken();
  if (!token) return null;
  return token.isAdmin
    ? 'admin'
    : getOrganisations()?.[0]?.organisationType ?? null;
}

export function getRole() {
  const token = getToken();
  if (!token) return null;
  // legacy solution: change MANGER to SCHOOL_DISTRICT_MANGER if resource type is a SCHOOL_DISTRICT
  const prexfix =
    getResourceType() === 'SCHOOL_DISTRICT' ? 'SCHOOL_DISTRICT_' : '';
  if (token.isAdmin || token.role === 'ADMIN') return 'ADMIN';
  if (getOrganisations()?.length) {
    return prexfix + getOrganisations()[0].role;
  }

  return null;
}

export function getResourceId() {
  const token = getToken();
  if (!token) return null;
  if (!token.organisations) return null;

  if (token.isAdmin) return 'default';
  return Object.keys(token.organisations)[0];
}

const roles = {
  ADMIN: 'ADMIN',
  MANAGER: 'MANAGER',
  COACH: 'COACH',
  STUDENT: 'STUDENT',
};

export default {
  install(Vue, { router }) {
    Vue.prototype.$user = {
      get id() {
        return getToken() && getToken().sub;
      },
      get token() {
        return getToken();
      },
      get role() {
        return getRole();
      },
      get resourceType() {
        return getResourceType();
      },
      get resourceId() {
        return getResourceId();
      },
      context: {
        get isAdmin() {
          return (
            router?.currentRoute?.params?.resourceId === 'default' &&
            getRole() === 'ADMIN'
          );
        },
        get role() {
          const role = router?.currentRoute?.params?.role;
          return role && role.toUpperCase();
        },
        get resourceType() {
          const resourceType = router?.currentRoute?.params?.resourceType;
          return resourceType && resourceType.toUpperCase();
        },
        get resourceId() {
          return router?.currentRoute?.params?.resourceId;
        },
      },
    };
  },
};
