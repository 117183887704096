import Vue from 'vue';
import VueScrollTo from 'vue-scrollto';
import locale from 'view-design/dist/locale/nl-NL';
import filters from '@/lib/filters';
import userContextManager from '@/lib/user-context-manager.js';
import App from './App.vue';
import router from './router';
import store from './store';
import Highcharts from 'highcharts';
import 'shepherd.js/dist/css/shepherd.css';

import { createProvider } from './vue-apollo';
import '@/components/global/_global-imports.js';
import './plugins/iview.js';
import './plugins/log.js';
import './plugins/get.js';
import './iview-theme/index.less';
import './styles/components/_shepherd.scss';

import './sentry';

Highcharts.setOptions({
  lang: {
    shortMonths: [
      'jan',
      'feb',
      'mrt',
      'apr',
      'mei',
      'jun',
      'jul',
      'aug',
      'sep',
      'okt',
      'nov',
      'dec',
    ],
    months: [
      'Januari',
      'Februari',
      'Maart',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Augustus',
      'Septembre',
      'Oktober',
      'November',
      'December',
    ],
    weekdays: [
      'Zondag',
      'Maandag',
      'Dinsdag',
      'Woensdag',
      'Donderdag',
      'Vrijdag',
      'Zaterdag',
    ],
  },
});

// add global filters
Object.keys(filters).forEach((filterName) => {
  const filter = filters[filterName];
  Vue.filter(filter.name, filter);
});

const ViewUI = require('view-design');

// global mixin to provide the current context based on route
Vue.mixin({
  computed: {
    $context() {
      if (this.$route) {
        return this.$route.params.resourceType?.toUpperCase();
      }
      return null;
    },
  },

  methods: {
    isSameResource(snakeCase, camelCase) {
      const toSnakeCase = (str) =>
        str &&
        str
          .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
          )
          .map((x) => x.toUpperCase())
          .join('_');

      return snakeCase === toSnakeCase(camelCase);
    },

    $showGenericError(
      title = 'Oeps!',
      content = 'Er ging iets mis. Probeer het later nog eens.',
    ) {
      this.$Modal.error({ title, content });
    },
  },
});

Vue.use(userContextManager, {
  router,
});
Vue.use(VueScrollTo);
Vue.use(ViewUI, {
  locale,
});

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  apolloProvider: createProvider(),
  render: (h) => h(App),
}).$mount('#app');
