import { formatDate, reverseDate, getDay, daysAgo, daysLeft } from '@/lib/date-helper.js';

export default {
  euro(value) {
    return Number(value).toLocaleString('nl-NL', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  },
  numberWithDots(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  },
  dayDate(date) {
    return getDay(date);
  },
  reverseDate(date) {
    return reverseDate(date);
  },
  date(date, showTime) {
    return formatDate(date, showTime);
  },
  daysAgo(date) {
    return daysAgo(date);
  },
  daysLeft(date) {
    return daysLeft(date);
  },
  formatQuantity(str) {
    return str.replace(/[,.]/g, m => (m === ',' ? '.' : ','));
  },
  highlight(text, query) {
    if (!query) {
      return text;
    }
    const check = new RegExp(`(<)(\\/?\\b[^<>]+>)|(${query})`, 'ig');
    return text.toString().replace(check, (match, matchedOpeningTag, matchedRestHTMLTag, matchedText) => (matchedText ? `<span class="sb_highlight">${matchedText}</span>` : match));
  },
  round(value = 0, decimals = 0) {
    // eslint-disable-next-line no-restricted-properties
    return Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
  },

  total(value, key) {
    const total = value.reduce((prev, cur) => prev + (key ? cur[key] : cur || 0), 0);
    return total;
  },

  average(value, key, divider) {
    const total = value.reduce((prev, cur) => prev + (key ? cur[key] : cur || 0), 0);
    return total / (divider || value.length);
  }

};
