<template>
  <div v-show="loading" class="sb_spinner">
    <div class="sb_spinner_inner" :style="spinnerStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'ClipLoader',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    color: {
      type: String,
      default: '#fff',
    },
    size: {
      type: String,
      default: '1.562rem',
    },
    radius: {
      type: String,
      default: '100%',
    },
    borderWidth: {
      type: String,
      default: '2px',
    },
  },
  computed: {
    spinnerStyle() {
      return {
        height: this.size,
        width: this.size,
        borderWidth: this.borderWidth,
        borderStyle: 'solid',
        borderColor: 'currentColor currentColor transparent',
        borderRadius: this.radius,
        background: 'transparent',
      };
    },
  },
};
</script>

<style lang="scss">
.sb_spinner {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;

  &_inner {
    animation: v-clipDelay 0.75s 0s infinite linear;
    animation-fill-mode: both;
    display: inline-block;
  }
}

@keyframes v-clipDelay {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.8);
    transform: rotate(180deg) scale(1);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
</style>
