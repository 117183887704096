import gql from 'graphql-tag';

export const resolvers = {
  Mutation: {
    incrementTotalJournalEntryCommentsCount: (
      _,
      { journalEntryId },
      { cache },
    ) => {
      try {
        const fragment = gql`
          fragment commentsTotalCount on JournalEntry {
            id
            comments {
              totalCount
            }
          }
        `;

        const data = cache.readFragment({
          id: journalEntryId,
          fragment,
        });

        cache.writeFragment({
          id: journalEntryId,
          fragment,
          data: {
            ...data,
            comments: {
              ...data.comments,
              totalCount: data.comments.totalCount + 1,
              __typename: 'PaginatedJournalEntryComment',
            },
          },
        });

        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
};
