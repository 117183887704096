import Vue from 'vue';
import SbButton from '@/components/global/SbButton';
import SbCheckbox from '@/components/SbCheckbox';
import SbContentIndent from '@/components/SbContentIndent';
import SbDivider from '@/components/SbDivider';
import SbDropdownButton from '@/components/global/SbDropdownButton';
import SbIcon from '@/components/global/SbIcon';
import SbFadedText from '@/components/global/SbFadedText';
import SbInfo from '@/components/SbInfo';
import SbMaxWidth from '@/components/global/SbMaxWidth';
import SbRadio from '@/components/global/SbRadio';
import SbSpacer from '@/components/global/SbSpacer';
import SbTitle from '@/components/global/SbTitle';
import SbBadge from '@/components/SbBadge.vue';
import SbConfirmWithToken from '@/lib/vm-confirm-with-token.vue';

Vue.component('SbIcon', SbIcon);
Vue.component('SbButton', SbButton);
Vue.component('SbCheckbox', SbCheckbox);
Vue.component('SbContentIndent', SbContentIndent);
Vue.component('SbDivider', SbDivider);
Vue.component('SbDropdownButton', SbDropdownButton);
Vue.component('SbFadedText', SbFadedText);
Vue.component('SbInfo', SbInfo);
Vue.component('SbMaxWidth', SbMaxWidth);
Vue.component('SbRadio', SbRadio);
Vue.component('SbSpacer', SbSpacer);
Vue.component('SbTitle', SbTitle);
Vue.component('SbBadge', SbBadge);
Vue.component('SbConfirmWithToken', SbConfirmWithToken);
