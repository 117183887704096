export default async function () {
  const infoText = await fetch('https://www.cloudflare.com/cdn-cgi/trace').then(res => res.text());
  const client = infoText.split('\n')
    .map(item => item.split('='))
    .reduce((output, [
      key,
      value
    ]) => {
      if (key) {
        // eslint-disable-next-line
        output[key] = value;
      }
      return output;
    }, {});
  return client;
}
