<template>
  <modal
    :value="value"
    width="80vw"
    footer-hide
    class="skip-tour-modal"
    @input="$emit('input', $event)"
  >
    <sb-title> Rondleiding overslaan</sb-title>

    <p class="modal-text" style="margin: 0">
      Wil je alleen deze rondleiding overslaan of wil je alle rondleidingen
      overslaan? NB. Je kunt via je profiel een rondleiding altijd nog een keer
      herstarten.
    </p>

    <div class="action-container">
      <button @click="skipCurrent">
        <sb-button button-type="primary" size="regular">
          Deze rondleiding overslaan
        </sb-button>
      </button>

      <button @click="onSkipAllTours">
        <sb-button button-type="light" size="regular">
          Geen enkele rondleiding meer laten zien
        </sb-button>
      </button>
      <button @click="onClosed()">
        <sb-button button-type="light" size="regular">
          Later beslissen
        </sb-button>
      </button>
    </div>
  </modal>
</template>

<script>
import tourDataMixin from '@/mixins/tourDataMixin';
export default {
  name: 'SbSkipTourModal',
  mixins: [tourDataMixin],
  props: {
    previousTourKey: { type: String, default: '' },
    value: { type: Boolean },
    onClosed: { type: Function, default: () => {} },
  },

  methods: {
    onSkipAllTours() {
      this.skipAll();
      this.onClosed();
    },
    skipCurrent() {
      this.updateOnboardingSetting(this.previousTourKey, 'SKIPPED');
      this.onClosed();
    },
  },
};
</script>

<style lang="scss">
.action-container {
  display: flex;
  gap: 10px;
  margin-top: 30px;
  flex-wrap: wrap;
}

.skip-tour-modal {
  .ivu-modal {
    .ivu-modal-content {
      margin: auto;
      height: auto;
      max-width: 1000px;
    }
  }
}
</style>
