<template>
  <div class="sb_word-definition-content">
    <h2>{{ wordEntry.word }}</h2>

    <sb-word-definition-definition
      v-for="(definition, index) of wordEntry.definitions"
      :key="`definition-${definition.id}`"
      :definition="definition"
      :number="index + 1"
    />

    <div
      v-for="(relatedWord, index) in relatedWords"
      :key="`${relatedWord.wordId}-${index}`"
      class="sb_word-definition-info_entry"
    >
      <hr class="row-2" />

      <template v-if="findRelation(relatedWord)">
        <h3>
          {{ get(findRelation(relatedWord), 'match') }}
        </h3>
        <div class="sb_word-definition-info_entry_key">
          <i>
            {{
              getWordRelationsLabel(get(findRelation(relatedWord), 'relation'))
            }}
          </i>
        </div>
      </template>

      <sb-word-definition-definition
        v-for="(definition, relatedIndex) of get(relatedWord, 'definitions')"
        :key="`definition-${definition.id}`"
        :definition="definition"
        :number="relatedIndex + 1"
      />
    </div>
  </div>
</template>

<script>
import { wordRelationsMixin } from '@/lib/word-relations';
import gql from 'graphql-tag';
import SbWordDefinitionDefinition from './SbWordDefinitionDefinition.vue';

export default {
  components: { SbWordDefinitionDefinition },
  mixins: [wordRelationsMixin],
  props: { wordEntry: { type: Object, required: true } },

  methods: {
    findRelation(relatedWord) {
      return this.wordEntry.relations.find((r) => r.match === relatedWord.word);
    },
  },

  apollo: {
    relatedWords: {
      skip() {
        return !this.wordEntry;
      },

      variables() {
        return {
          filter: {
            word: {
              in: this.wordEntry.relations.map((r) => r.match),
            },
          },
        };
      },

      update(data) {
        return data.wordEntries.edges
          .filter(
            ({ node }) =>
              !!this.wordEntry.relations.find((r) => r.match === node.word) &&
              node.relations.find((r) => r.match === this.wordEntry.word),
          )
          .map((e) => e.node);
      },

      query: gql`
        query WordDefinitionContent_RelatedWords(
          $first: Int
          $filter: WordEntriesFilter
        ) {
          wordEntries(first: $first, filter: $filter) {
            edges {
              node {
                id
                word
                relations {
                  match
                }
                definitions {
                  id
                  pos
                  audio
                  image
                  content
                  examples
                }
              }
            }
          }
        }
      `,
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_word-definition-content {
  &_entry {
    display: grid;
    gap: 1rem 1.5rem;
    grid-template-columns: repeat(2, max-content);

    &_key {
      color: $brand-dark-gray;
    }
  }
}
</style>
