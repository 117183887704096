<template>
  <div
    class="sb_environment-border"
    :class="'s_'+ environment"
  >
    <div class="sb_environment-border_top"></div>
    <div class="sb_environment-border_right"></div>
    <div class="sb_environment-border_bottom"></div>
    <div class="sb_environment-border_left"></div>
  </div>
</template>

<script>
export default {
  name: 'SbEnvironmentBorder',
  props: {
    environment: {
      type: String,
      default: 'admin'
    }
  }
};
</script>

<style lang="scss">
  .sb_environment-border {
    @media print {
      display: none;
    }

    &.s_default {
      > div {
        background: $brand-env-admin;
      }
    }

    &.s_coaches {
      > div {
        background: $brand-env-coach;
      }
    }

    &.s_student {
      > div {
        background: $brand-env-student;
      }
    }

    &.s_schools {
      > div {
        background: $brand-env-school;
      }
    }

    &.s_schooldistricts {
      > div {
        background: $brand-env-schooldistrict;
      }
    }
  }

  .sb_environment-border_top {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 8px;
    background: $brand-white;
  }

  .sb_environment-border_right {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    width: 8px;
    height: 100%;
    background: $brand-white;
  }

  .sb_environment-border_bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 9999;
    width: 100%;
    height: 8px;
    background: $brand-white;
    border-bottom-style: 0;
  }

  .sb_environment-border_left {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 8px;
    height: 100%;
    background: $brand-white;
    border-bottom-style: 0;
  }
</style>
