<template>
  <div class="sb_word-definition-info_definition row-4">
    <div class="sb_word-definition-info_definition_intro">
      <div>
        <sb-title>
          {{ number }}
          <small style="font-size: 0.6em">
            <i>{{ definition.pos }}</i>
          </small>
        </sb-title>

        <slot name="prepend" />

        <audio
          v-if="definition.audio"
          class="row-2"
          style="display: block"
          :src="definition.audio"
          controls
        />

        <div v-if="definition.image" class="row-2">
          <img
            class="sb_word-definition-info_definition_image"
            :src="definition.image"
            :alt="definition.content"
          />
        </div>
      </div>
    </div>

    <p style="white-space: pre-wrap">{{ definition.content }}</p>

    <div class="row-2">
      <p
        v-for="(example, exampleIndex) in definition.examples"
        :key="`example-${definition.id}-${exampleIndex}`"
      >
        <i> "{{ example }}" </i>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    definition: { type: Object, required: true },
    number: { type: [Number, String], required: true },
  },
};
</script>

<style lang="scss">
.sb_word-definition-info_definition {
  &_image {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 10px;
    display: block;
  }
}
</style>
