<template>
  <div class="sb_max-width">
    <slot></slot>
  </div>
</template>

<script>
export default {
  maxWidth: {
    type: String,
    default: '600',
    validator: (value) =>
      ['600', '700', '800', '900', '1000', '1100', '1200'].includes(value),
  },
};
</script>

<style lang="scss">
.sb_max-width {
  //
  // WIDTH VARIATIONS
  //
  $width: [600 700 800 900 1000 1100 1200 ];

  @each $item in $width {
    &[max-width='#{$item}'] {
      max-width: rem-calc($item);
    }
  }

  //
  // CENTER
  //
  &[center] {
    margin: 0 auto;
  }
}
</style>
