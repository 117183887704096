<template>
  <sb-drawer
    :show="
      $store.state.doShowWordDefinitions &&
      $store.state.doShowWordDefinitionInfo &&
      !!$store.state.activeWordDefinition
    "
    @close="$store.state.doShowWordDefinitionInfo = false"
  >
    <sb-word-definition />
  </sb-drawer>
</template>

<script>
import SbDrawer from '@/components/SbDrawer.vue';
import SbWordDefinition from './SbWordDefinition.vue';

export default {
  components: { SbDrawer, SbWordDefinition },
};
</script>
