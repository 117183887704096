<template>
  <label class="sb_radio">
    {{ label }}
    <input
      class="sb_radio_radio"
      type="radio"
      :checked="isChecked"
      :value="value"
      v-bind="$attrs"
      @change="$emit('change', $event.target.value)"
    />
    <span class="sb_radio_checkmark"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: undefined,
    },
  },
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_radio {
  display: block;
  position: relative;
  padding-left: 2rem;
  padding-top: 0.1rem;
  margin-bottom: 0.4rem;
  cursor: pointer;
  user-select: none;
  font-size: 1rem;
  color: $brand-black;

  //
  //  INPUT
  //
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  //
  //  CHECKMARK
  //
  &_checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 26px;
    width: 26px;
    border-radius: 50%;
    background-color: $brand-white;
    border: 2px solid #ddd;

    //  CHECKMARK - DOT
    &::after {
      content: '';
      position: absolute;
      display: none;
      top: 5px;
      left: 5px;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $brand-primary;
    }
  }

  //
  //  STATE - HOVER
  //
  &:hover input ~ .sb_radio_checkmark {
    background-color: #eee;
  }

  //
  //  STATE - CHECKED
  //
  input:checked ~ .sb_radio_checkmark {
    border-color: $brand-primary-lighter;

    &::after {
      display: block;
    }
  }
}
</style>
