import Vue from 'vue';
import Vuex from 'vuex';
import track from './modules/track';
import onboarding from './modules/onboarding';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { track },
  state: {
    loading: false,
    lineHeight: 1,
    letterSpacing: 1,
    fontValue: 0,
    doShowLineNumbers: false,
    doShowWordDefinitions: false,
    doShowWordDefinitionInfo: false,
    activeWordDefinition: null,
    readingComprehensionQALookup: {},
  },
  mutations: {
    updateNewMail(state, payload) {
      state.newMail = payload;
    },
  },
  actions: {
    updateNewMail({ commit }, payload) {
      commit('updateNewMail', payload);
    },
  },
  modules: {
    track,
    onboarding,
  },
});
