import Vue from 'vue';

const key = '__mixin_get__';

if (!Vue[key]) {
  Vue[key] = true;

  Vue.mixin({
    methods: {
      get(obj, ...path) {
        let value;

        for (const property of path) {
          value = (value ?? obj)?.[property];
          if (!value) return value;
        }

        return value;
      },
    },
  });
}
