<template>
  <div id="app">
    <sb-error-boundary
      :stop-propagation="true"
      @errorCaptured="handleBoundaryError"
    >
      <symbols />
      <sb-environment-border :environment="environment()" />
      <router-view />
      <div class="sb_app-version">
        {{ version }}
      </div>

      <sb-word-definition-drawer />
    </sb-error-boundary>
    <sb-graph-q-l-error-handler />
    <sb-skip-tour-modal
      :value="showSkipTourModal"
      :previous-tour-key="previousTourKey"
      :on-closed="() => (showSkipTourModal = false)"
    />
  </div>
</template>

<script>
import SbEnvironmentBorder from '@/components/SbEnvironmentBorder';
import SbErrorBoundary from '@/components/SbErrorBoundary';
import SbGraphQLErrorHandler from '@/components/SbGraphQLErrorHandler';
import Symbols from '@/components/SvgSymbols';
import SbWordDefinitionDrawer from './components/SbWordDefinitionDrawer.vue';
import { platformSettingsMixin } from '@/mixins/platformSettingsMixin';
import SbSkipTourModal from '@/components/SbSkipTourModal.vue';
import tourMixin from './mixins/tourMixin';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  components: {
    SbErrorBoundary,
    SbGraphQLErrorHandler,
    SbEnvironmentBorder,
    Symbols,
    SbWordDefinitionDrawer,
    SbSkipTourModal,
  },

  mixins: [platformSettingsMixin, tourMixin],

  data() {
    return {
      activeRow: null,
      version: process.env.VUE_APP_VERSION,
    };
  },

  computed: {
    ...mapGetters('onboarding', ['getCurrentTourKey']),
    baseUrl() {
      const url = window.location.href;
      return `${url.split('/')[0]}//${url.split('/')[2]}`;
    },
  },

  mounted() {
    console.groupCollapsed(`Leeshelden ${this.version}`);

    const self = this;
    console.log({
      root: this,
      get user() {
        return self.$user ?? null;
      },
    });

    console.groupEnd();

    // extends router to have a navigateback function
    this.$router.navigateBack = () => {
      if (window.history?.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    };
  },

  methods: {
    environment() {
      if (this.$route.meta && this.$route.meta.allowUnauthorized) return '';
      if (this.$user.role === 'STUDENT') return 'student';
      if (this.$user.context.isAdmin) return 'default';
      if (this.$user.role === 'COACH') return 'coaches';
      if (this.$user.context.resourceType === 'SCHOOL_DISTRICT')
        return 'schooldistricts';
      if (this.$user.context.resourceType === 'SCHOOL') return 'schools';

      return this.$user.context.resourceId;
    },

    handleBoundaryError({ err }) {
      // Handle GraphQL errors seperately in graphqlErrorHandler
      if (err.message && err.message.includes('GraphQL error')) return;

      console.error('Boundary error', err);

      this.$Message.error(err.message);
      throw new Error(err.message);

      // return err;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/app.scss';

.sb_app-version {
  position: fixed;
  bottom: 10px;
  left: 14px;
  z-index: 9999;
  font-size: 8px;
  opacity: 0.5;
}
</style>
