import { TOUR_KEYS } from '@/shepherd/tourkeys';
import updateOnboardingSettingsMutation from '@/shepherd/gql/update.gql';
import getOnboardingSettingsQuery from '@/shepherd/gql/get.gql';
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('onboarding', ['setOnboardingTours']),
    parseData(data, set = true) {
      const tours = Object.keys(data).reduce((acc, key) => {
        if (key === '__typename') return acc;
        const dataHasProperty = data.hasOwnProperty(key);
        if (dataHasProperty) {
          acc[key] = data[key] || null;
        }

        return acc;
      }, {});

      if (set) {
        this.setOnboardingTours(tours);
      }
      return tours;
    },

    async getOnboardingSettings() {
      const { data } = await this.$apollo.query({
        query: getOnboardingSettingsQuery,
      });
      const { onboardingTours: tours } = data.me;
      return this.parseData(tours);
    },

    async updateOnboardingSetting(key, value) {
      const currentSettings = await this.getOnboardingSettings();

      const updatedKeys = {
        ...currentSettings,
        [key]: value,
      };

      const result = await this.$apollo.mutate({
        mutation: updateOnboardingSettingsMutation,
        variables: {
          input: {
            id: this.$user.id,
            data: {
              onboardingTours: updatedKeys,
            },
          },
        },
      });

      const newTours = result?.data?.updateUser?.onboardingTours;
      return this.parseData(newTours, true);
    },

    async skipAll() {
      const tours = Object.values(TOUR_KEYS).reduce((acc, key) => {
        acc[key] = 'SKIPPED';
        return acc;
      }, {});

      return this.updateAll(tours);
    },

    async resetAll() {
      const tours = Object.values(TOUR_KEYS).reduce((acc, key) => {
        acc[key] = null;
        return acc;
      }, {});
      this.setOnboardingTours({});
      return this.updateAll(tours);
    },

    async updateAll(tours) {
      const result = await this.$apollo.mutate({
        mutation: updateOnboardingSettingsMutation,
        variables: {
          input: {
            id: this.$user.id,
            data: {
              onboardingTours: tours,
            },
          },
        },
      });

      const newTours = result?.data?.updateUser?.onboardingTours;
      return this.parseData(newTours);
    },
  },
};
