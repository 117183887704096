<template>
  <div>
    <p>{{ content }}</p>
    <p>
      Voer <span style="font-family: monospace">"{{ token }}"</span> in om te
      bevestigen:
    </p>

    <i-input v-model="model" />
  </div>
</template>

<script>
export function vmConfirmWithToken(token, title, content) {
  let handleOk;
  return new Promise((resolve) => {
    this.$Modal.confirm({
      title,
      content,
      closable: true,
      onOk: () => handleOk(),
      onCancel: () => setTimeout(() => resolve(false), 300),
      render(h) {
        return h('sb-confirm-with-token', {
          props: { token, title, content, resolver: resolve },
          on: { handler: (handler) => (handleOk = handler) },
        });
      },
    });
  });
}

export default {
  name: 'SbConfirmWithToken',

  props: {
    token: { type: String, required: true },
    title: { type: String, required: true },
    content: { type: String, required: true },
    resolver: { type: Function, required: true },
  },

  data() {
    return { model: '' };
  },

  watch: {
    model(value) {
      const submitButton = this.getSubmitButton();
      if (!submitButton) return;

      submitButton.disabled = value !== this.token;
    },
  },

  mounted() {
    const submitButton = this.getSubmitButton();
    if (submitButton) submitButton.disabled = true;
    this.$emit('handler', this.handleSubmit);
  },

  methods: {
    getSubmitButton() {
      return document.querySelector(
        '.ivu-modal-confirm-footer > .ivu-btn.ivu-btn-primary',
      );
    },

    handleSubmit() {
      this.resolver(true);
    },
  },
};
</script>
