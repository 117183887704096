import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloLink } from 'apollo-link';
import {
  createApolloClient,
  restartWebsockets,
} from 'vue-cli-plugin-apollo/graphql-client';
import { SentryRequestIdApolloLink } from '@/sentry';
import {
  InMemoryCache,
  IntrospectionFragmentMatcher,
} from 'apollo-cache-inmemory';
import { resolvers } from './vue-apollo-resolvers';
import gql from 'graphql-tag';
import ApolloErrorHandler from '@/lib/apollo-error-handler.js';
import schema from '@/graphql/schema.json';
import Logout from '@/graphql/mutations/Logout.gql';
import { getUserByUsername, switchToUser } from './lib/user-management';
import { parseJwt } from './lib/user-context-manager';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: schema,
});

let errorHandler;
let refreshErrorHandler;

export function graphqlErrorHandler(_errorHandler) {
  errorHandler = _errorHandler;
}

export function onRefreshTokenError(_errorHandler) {
  refreshErrorHandler = _errorHandler;
}

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';
const REFRESH_TOKEN = 'leesreis-refresh-token';

// Http endpoint
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP;
const wordpressHttpEndpoint = process.env.VUE_APP_WORDPRESS_GRAPHQL_HTTP;

// Files URL root
export const filesRoot =
  process.env.VUE_APP_FILES_ROOT ||
  httpEndpoint.substr(0, httpEndpoint.indexOf('/graphql'));

Vue.prototype.$filesRoot = filesRoot;

// Manually call this when user log in
export async function onLogin(apolloClient, token) {
  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  if (apolloClient && apolloClient.wsClient) {
    restartWebsockets(apolloClient.wsClient);
  }
  try {
    // await apolloClient.cache.reset();
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  try {
    await apolloClient.mutate({ mutation: Logout });
    await apolloClient.clearStore();
    window.localStorage.removeItem('apollo-token');
    window.localStorage.removeItem('current-user');
    window.localStorage.removeItem('users');

    if (apolloClient?.wsClient) {
      restartWebsockets(apolloClient.wsClient);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}

export async function onSwitchUser(apolloClient, username) {
  try {
    // await apolloClient.clearStore();
    const user = getUserByUsername(username);
    if (!user) throw new Error('User not found');
    const { token } = user;

    if (token) {
      const parsed = parseJwt(token);
      const expiration = parsed.exp;
      const expirationStamp = new Date(expiration * 1000).getTime();
      const timestampNow = Math.floor(new Date().getTime());
      console.log({ expirationStamp, timestampNow });
      if (timestampNow < timestampNow) {
        console.log('token expired, refreshing');
        await fetchNewAccessToken();
        return;
      }
    }

    window.localStorage.setItem('apollo-token', token);
    switchToUser(user);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}

export async function onAddAnotherAccount(apolloClient) {
  try {
    await apolloClient.clearStore();
    window.localStorage.removeItem('apollo-token');
  } catch (err) {
    console.error(err);
  }
}

async function fetchNewAccessToken() {
  const accessToken = localStorage.getItem(AUTH_TOKEN);

  if (!accessToken) {
    console.log('no accesstoken, redirecting to /');
    if (refreshErrorHandler) refreshErrorHandler();
  }
  try {
    const res = await fetch(httpEndpoint, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify({
        query: `
        mutation {
          refreshToken {
            accessToken
          }
        }`,
      }),
      headers: {
        authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    const json = await res.json();
    const newAccessToken = json.data.refreshToken.accessToken;
    localStorage.setItem(AUTH_TOKEN, newAccessToken);

    return `Bearer ${newAccessToken}`;
  } catch (e) {
    localStorage.removeItem(AUTH_TOKEN);
    if (refreshErrorHandler) refreshErrorHandler();
    console.error(e);
  }
}

function errorHandlerLink() {
  return ApolloErrorHandler({
    errorHandler(e) {
      errorHandler(e);
    },
    isUnauthenticatedError(graphQLError) {
      const { extensions } = graphQLError;
      return extensions?.exception?.message === 'Unauthorized';
    },
    fetchNewAccessToken,
    authorizationHeaderKey: 'authorization',
  });
}

// Config
const defaultOptions = {
  httpEndpoint,
  tokenName: AUTH_TOKEN,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: ApolloLink.from([SentryRequestIdApolloLink, errorHandlerLink()]),
  cache: new InMemoryCache({
    fragmentMatcher,
    dataIdFromObject: (object) => object.id,
  }),
};

const defaultWordpressOptions = {
  httpEndpoint: wordpressHttpEndpoint,
  persisting: false,
  websocketsOnly: false,
  ssr: false,
  link: ApolloLink.from([errorHandlerLink()]),
  cache: new InMemoryCache({
    fragmentMatcher,
  }),
};

const typeDefs = gql`
  type Mutation {
    incrementTotalJournalEntryCommentsCount(journalEntryId: ID!): Boolean
  }
`;

export function createProvider(options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    typeDefs,
    resolvers,
    ...defaultOptions,
    ...options,
  });
  apolloClient.wsClient = wsClient;

  const { apolloClient: wordpressClient } = createApolloClient({
    ...defaultWordpressOptions,
    ...options,
  });

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    clients: {
      apolloClient,
      wordpressClient,
    },
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {},
    },
  });

  return apolloProvider;
}
