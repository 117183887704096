import Vue from 'vue';
import { setContext } from 'apollo-link-context';
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import getClientInfo from '@/lib/client-info';

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    environment: process.env.VUE_APP_NODE_ENV,
    release: process.env.VUE_APP_RELEASE_GIT_SHORT_SHA,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    logLevel: parseInt(process.env.VUE_APP_SENTRY_LOG_LEVEL),
    integrations: [
      new VueIntegration({
        Vue,
        tracing: true,
      }),
      new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: parseFloat(process.env.VUE_APP_SENTRY_TRACES_SAMPLE_RATE),
  });

  // fetch client info
  getClientInfo()
    .then((client) => {
      const parsedClient = client;
      parsedClient.ip_address = client.ip;
      delete parsedClient.ip;
      Sentry.setUser(client);
    })
    .catch(console.error);
}

export const SentryRequestIdApolloLink = setContext((_, { headers }) => {
  const transactionId = Math.random().toString(36).substr(2, 9);
  Sentry.configureScope((scope) =>
    scope.setTag('transaction_id', transactionId),
  );
  return {
    headers: {
      ...headers,
      'x-request-id': transactionId,
    },
  };
});
