<template>
  <label class="sb_checkbox">
    {{ label }}
    <input
      class="sb_checkbox_checkbox"
      type="checkbox"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    />
    <span class="sb_checkbox_checkmark"></span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
      required: false,
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isChecked() {
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(this.value);
      }
      // Note that `true-value` and `false-value` are camelCase in the JS
      return this.modelValue === this.trueValue;
    },
  },
  methods: {
    updateInput(event) {
      const isChecked = event.target.checked;
      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_checkbox {
  display: block;
  position: relative;
  padding-left: 2rem;
  padding-top: 0.1rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  user-select: none;
  font-size: 0.8rem;
  color: $brand-black;

  //
  // INPUT
  //
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  //
  // CHECKMARK
  //
  &_checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 23px;
    width: 23px;
    border-radius: 4px;
    background-color: $brand-white;
    border: 2px solid #ddd;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 7px;
      top: 1px;
      width: 7px;
      height: 13px;
      border: solid $brand-white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }

  //
  // STATE - HOVER
  //
  &:hover input ~ .sb_checkbox_checkmark {
    background-color: #eee;
  }

  //
  // STATE - CHECKED
  //
  input:checked ~ .sb_checkbox_checkmark {
    background-color: $brand-primary;
    border-color: $brand-primary;

    &::after {
      display: block;
    }
  }
}
</style>
