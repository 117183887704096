<template>
  <div class="sb_divider" :style="style" :on-background="onBackground">
    <slot></slot>
    <slot name="left"></slot>
    <div class="sb_divider_line-left"></div>
    <h2>{{ title }}</h2>
    <div class="sb_divider_line-right"></div>
    <div class="sb_divider_slot-right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbDivider',
  props: {
    /**
     * The title, will be placed in a H2
     */
    title: {
      type: String,
      default: '',
    },
    /**
     * On what background to place. Useful when adding elements in the slot right, possible values: 'white', 'lightest-gray'
     */
    onBackground: {
      default: 'white',
      validator: (value) => ['white', 'lightest-gray'].includes(value),
    },

    topSpace: {
      type: String,
      default: undefined,
    },

    topPadding: {
      type: String,
      default: undefined,
    },
  },

  computed: {
    style() {
      const topSpaceStyle =
        this.topSpace != undefined && `margin-top: ${this.topSpace}`;
      const topPaddingStyle =
        this.topPadding != undefined &&
        `padding-top: ${this.topPadding}; padding-bottom: ${this.topPadding}`;

      const styles = [topSpaceStyle, topPaddingStyle]
        .filter(Boolean)
        .join('; ');
      return styles;
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_divider {
  position: relative;
  display: flex;
  // margin: 30px 0 10px 0;
  margin: 60px 0 30px 0;

  h2 {
    font-weight: 500;
    font-size: 20px;
    margin: 0 10px;
  }

  &_line-left {
    flex: 0 0 15px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $brand-light-gray;
      top: 12px;
    }
  }

  &_line-right {
    flex: 1 1 auto;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background: $brand-light-gray;
      top: 12px;
    }
  }

  &_slot-right {
    position: absolute;
    top: 50%;
    right: 10px;
    padding: 0 10px;
    display: flex;
    align-items: flex-end;
    transform: translateY(-50%);
  }

  .sb_info {
    top: 3px !important;
    left: 7px !important;

    @media screen and (min-width: 860px) {
      left: -22px !important;
    }
  }

  &[on-background='white'] {
    .sb_divider_slot-right {
      background: $brand-white;
    }
  }
  &[on-background='lightest-gray'] {
    .sb_divider_slot-right {
      background: $brand-lightest-gray;
    }
  }
}
</style>
