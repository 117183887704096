<template>
  <div class="sb_dropdown-button">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.sb_dropdown-button {
  white-space: nowrap;
  margin: 0;
  background: none;
  outline: none;
  border-radius: 0.3rem;
  border: none;
  text-align: left;
  transition: opacity 0.3s ease;
  font-size: 1rem;
  padding: 0.8rem 1rem;
  letter-spacing: 0;
  background: $brand-white;
  color: $default-text-color;
  user-select: none;
  text-decoration: none;
  transition: color 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  border-bottom: solid 1px $brand-light-gray;
  border-radius: 0;
  transition: background-color 0.3s ease;

  &:hover {
    color: $brand-primary;
    background-color: $brand-lightest-gray;
  }

  &:disabled {
    color: $brand-gray;
  }
}
</style>
