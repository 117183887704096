<template>
  <div class="sb_drawer" :class="{ s_show: show }" @click="$emit('close')">
    <div class="sb_drawer_box" :class="{ s_show: show }" @click.stop>
      <slot />
      <sb-icon
        class="sb_drawer_close"
        icon-id="icon_close"
        @click.native="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import SbIcon from './global/SbIcon.vue';
export default {
  components: { SbIcon },
  props: {
    show: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss" scoped>
.sb_drawer {
  position: fixed;
  background: rgba(0, 0, 0, 0.2);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 999;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &.s_show {
    opacity: 1;
    pointer-events: unset;
  }

  &_box {
    position: relative;
    background: $brand-white;
    height: 100%;
    width: 680px;
    padding: 1rem 2rem;
    overflow: auto;
    transform: translateX(10%);
    transition: transform 0.3s ease-in-out;

    &.s_show {
      transform: unset;
    }
  }

  &_close {
    position: absolute;
    right: 2rem;
    top: 2rem;
    font-size: 1.5rem;
    cursor: pointer;
  }
}
</style>
