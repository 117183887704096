export function getUsers() {
  const usersFromStorage = localStorage.getItem('users');
  if (!usersFromStorage) return [];
  return JSON.parse(usersFromStorage);
}

function addToUsers(user) {
  const users = getUsers();
  if (users.find((u) => u.username === user.username)) return;
  const newUserList = [...users, user];
  localStorage.setItem('users', JSON.stringify(newUserList));
}

export function switchToUser(user) {
  const { username, role, token } = user;
  setCurrentUser(username, role, token);
}

/**
 *
 * @param {*} username
 * @param {*} role
 * @param {*} token
 * @param {*} id
 */
export function setCurrentUser(username, role, token, id) {
  try {
    const user = { username, role, token, id };
    localStorage.setItem('current-user', JSON.stringify(user));
    addToUsers(user);
  } catch (err) {
    console.log(err);
  }
}

export function getUserByUsername(username) {
  const users = getUsers();
  if (!users) return null;

  return users.find((user) => user.username === username);
}

export function getCurrentUser() {
  const user = localStorage.getItem('current-user');
  if (!user) return null;
  return JSON.parse(user);
}

/**
 * Removes the current user from local storage and from list of saved users.
 */
export function removeCurrentUser() {
  const currentUser = getCurrentUser();
  localStorage.removeItem('current-user');
  const users = getUsers();
  if (users.length > 0) {
    const newUsers = users.filter(
      (user) => user?.username !== currentUser?.username,
    );
    localStorage.setItem('users', JSON.stringify(newUsers));
  }
}
