<template>
  <div
    class="sb_badge"
    :class="'v_' + color "
  >
    <div
      class="sb_badge_text"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SbIndicator',
  props: {

    /**
     * The inidcator values, possible values: 'red', 'green'
     */
    color: {
      default: 'red',
      validator: value => [
        'red',
        'green'
      ].includes(value)
    }
  }
};
</script>

<style lang="scss" scoped>
  .sb_badge {
    position: relative;
    z-index: 3;
    min-width: 20px;
    height: 20px;
    background: rgba($brand-black, .5);
    border-radius: 10px;
    margin: 0 5px 0 0;

    &_text {
      color: rgba($brand-white, .8);
      line-height: 20px;
      font-weight: bold;
      font-size: 13px;
      transform: translateY(1px);
      margin: 0 6px;
    }

    &.v_green {
      background: $brand-vibe-red;

    }
    &.v_red {
      background: $brand-vibe-red;
    }
  }
</style>
