<template>
  <div></div>
</template>

<script>
import {
  onLogout,
  graphqlErrorHandler,
  onRefreshTokenError,
} from '@/vue-apollo';
import { hasGraphQLError } from '@/lib/util-helper';
import { removeCurrentUser } from '@/lib/user-management';

export default {
  mounted() {
    graphqlErrorHandler(this.handleGraphqlError);
    onRefreshTokenError(this.handleRefreshTokenError);
  },
  methods: {
    logout() {
      if (this.$route.name !== 'Login') {
        console.log('logout');
        onLogout(this.$apollo.provider.defaultClient);
        this.$router.push({
          name: 'Login',
        });
      }
    },
    showErrorModal(content) {
      throw new Error(content);
      // this.$Modal.error({
      //   title: 'Foutmelding',
      //   content,
      // });
    },
    handleGraphqlError(error) {
      let res;
      if (error.networkError && error.networkError.statusCode === 502) {
        res = 'Backend server kon niet worden bereikt';
      } else {
        res = hasGraphQLError(error);
      }

      console.log(res);

      // stop global loading state
      this.$store.state.loading = false;

      if (res?.includes('ForbiddenException')) {
        this.$Modal.info({
          title: 'Toegang geweigerd',
          content:
            'Je account heeft niet de juiste rechten om deze gegevens te bekijken.',
        });
      } else if (res?.includes('SeatsRequiredException')) {
        this.$Modal.info({
          title: 'Geen seats',
          content:
            'Deze school beheert zelf haar seats: Er moet ten minste één seat beschikbaar zijn.',
        });
      } else if (res?.includes('UnauthorizedException')) {
        // Don't show error message when user is not authorized. Because refreshtoken flow will be started.
      } else if (res?.includes('UsernameExistsException')) {
        this.$Message.error('Deze gebruikersnaam bestaat al');
      } else if (res?.includes('TemporaryPasswordInUseException')) {
        // Don't show error message when user is using a temporary password
        return;
      } else {
        const msg = res?.slice(0, 80) ?? error;
        console.log('msg', res);
        this.$Message.error(msg);
      }

      return res;
    },
    handleRefreshTokenError() {
      removeCurrentUser();
      this.$router.push('/');
    },
  },
};
</script>

<style></style>
