import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  getToken,
  getRole,
  getResourceType,
  getResourceId,
} from '@/lib/user-context-manager';

import { createProvider } from '@/vue-apollo';

const apollo = createProvider();

Vue.use(VueRouter);

export const routes = [
  {
    path: '/dictionary',
    name: 'SystemMaintainerDictionary',
    component: () =>
      import(
        /* webpackChunkName: "Dictionary" */ '@/views/Default/DictionaryEnvironmentDictionary.vue'
      ),

    meta: { title: 'Woordenboek', hideFromMenu: true },
  },

  {
    path: '/dictionary/add',
    name: 'SystemMaintainerDictionaryAdd',
    component: () =>
      import(
        /* webpackChunkName: "Dictionary" */ '@/views/Default/DictionaryEnvironmentAdd.vue'
      ),
    meta: {
      hideFromMenu: true,
      title: 'Woord definiëren',
    },
  },

  {
    path: '/dictionary/edit/:wordId',
    name: 'SystemMaintainerDictionaryEdit',
    component: () =>
      import(
        /* webpackChunkName: "Dictionary" */ '@/views/Default/DictionaryEnvironmentEdit.vue'
      ),
    meta: {
      hideFromMenu: true,
      title: 'Woord aanpassen',
    },
  },

  {
    path: '/card-preview',
    name: 'SessionCardPreview',
    meta: { allowUnauthorized: true },
    component: () =>
      import('@/views/Default/SessionCards/SbCardsSessionTraining.vue'),
  },
  {
    path: '/exercise',
    name: 'StudentParentCardExercise',
    component: () =>
      import(
        /* webpackChunkName: "PrivateStudent" */ '@/views/Default/SessionCards/SbCardsSessionTraining.vue'
      ),
  },

  {
    path: '/exercise-finish',
    name: 'StudentParentCardExerciseFinish',
    component: () =>
      import(
        /* webpackChunkName: "PrivateStudent" */ '@/views/StudentFinishCardExercise.vue'
      ),
  },

  {
    path: '/card-print',
    name: 'SessionCardPrint',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import('@/views/Default/SessionCards/SbCardsSessionTraining.vue'),
  },

  {
    path: '/',
    name: 'Home',
    beforeEnter: (to, from, next) => {
      console.log('beforeEnter', getToken());

      return next({
        name: 'Resource',
        params: {
          role: getRole()?.toLowerCase(),
          resourceType: getResourceType()?.toLowerCase(),
          resourceId:
            !getResourceId() && getRole() === 'ADMIN'
              ? 'default'
              : getResourceId(),
        },
      });
    },
  },
  {
    path: '/components',
    name: 'Components',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/Components.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '@/views/ForgotPassword.vue'
      ),
  },
  {
    path: '/forgot-username',
    name: 'ForgotUsername',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '@/views/ForgotUsername.vue'
      ),
  },
  {
    path: '/new-password',
    name: 'NewPassword',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "ForgotPassword" */ '@/views/NewPassword.vue'
      ),
  },
  {
    path: '/new-user',
    name: 'NewUser',
    meta: {
      allowUnauthorized: true,
    },
    component: () =>
      import(/* webpackChunkName: "ForgotPassword" */ '@/views/NewUser.vue'),
  },
  {
    path: '/choose-role',
    name: 'ChooseRole',
    component: () =>
      import(/* webpackChunkName: "ChooseRole" */ '@/views/ChooseRole.vue'),
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () =>
      import(
        /* webpackChunkName: "UserTypeEnvironment" */ '@/views/UserTypeEnvironment.vue'
      ),
    redirect: {
      name: 'AdminDefault',
    },
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () =>
      import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue'),
    meta: {
      title: 'Profiel',
      hideBorder: true,
    },
  },
  {
    path: '/profile-edit',
    name: 'ProfileEdit',
    component: () =>
      import(/* webpackChunkName: "Profile" */ '@/views/ProfileEdit.vue'),
    meta: {
      title: 'Profiel',
    },
  },
  {
    /*
      role is defined (admin / coach / manager etc.)
      resourceType: (school / schooldistrict  etc.)
      resourceId: id of the resourceType
    */
    path: '/:role/:resourceType/:resourceId',
    name: 'Resource',
    meta: {
      role() {
        return getRole();
      },
    },
    redirect: {
      name: 'ResourceDashboard',
    },
    props: true,
    component: () =>
      import(
        /* webpackChunkName: "UserTypeEnvironment" */ '@/views/UserTypeEnvironment.vue'
      ),
    children: [
      {
        path: 'session',
        name: 'Session',
        meta: {
          hideFromMenu: true,
        },
        component: () =>
          import(
            /* webpackChunkName: "SessionCards" */ '@/views/Default/SessionCards/SessionCards.vue'
          ),
      },
      {
        path: 'dashboard',
        name: 'ResourceDashboard',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDashboard" */ '@/views/Default/ResourceDashboard.vue'
          ),
        meta: {
          title: 'Dashboard',
          hideFor: [{ role: 'STUDENT' }],
        },
      },

      /*******************
       *
       *  STUDENT ENVIRONMENT
       *
       ***************** */
      {
        path: 'dashboard',
        name: 'ResourceDashboard',
        component: () =>
          import(
            /* webpackChunkName: "PrivateStudent" */ '@/views/Default/ResourceDashboard.vue'
          ),
        meta: {
          title: 'Kaarten oefenen',
          showFor: [{ role: 'STUDENT' }],
        },
      },

      {
        path: 'stickers',
        name: 'StudentStickers',
        component: () =>
          import(
            /* webpackChunkName: "PrivateStudent" */ '@/views/Default/StudentStickers.vue'
          ),
        meta: {
          title: 'Stickers',
          showFor: [{ role: 'STUDENT' }],
          // TODO show this route in menu, when stickers feature is ready
          hideFromMenu: true,
        },
      },
      {
        path: 'ouders',
        name: 'StudentParents',
        component: () =>
          import(
            /* webpackChunkName: "PrivateStudent" */ '@/views/Default/StudentParents.vue'
          ),
        meta: {
          title: 'Ouders',
          showFor: [
            {
              role: 'STUDENT',
            },
          ],
        },
        redirect: {
          name: 'StudentParentsProgress',
        },
      },
      {
        path: 'ouders/voortgang',
        name: 'StudentParentsProgress',
        component: () =>
          import(
            /* webpackChunkName: "PrivateStudent" */ '@/views/Default/StudentParentsProgress.vue'
          ),
        meta: {
          title: 'Voortgang',
          showFor: [{ role: 'STUDENT' }],
        },
      },
      {
        path: 'ouders/sessies',
        name: 'StudentParentsTrainings',
        component: () =>
          import(
            /* webpackChunkName: "PrivateStudent" */ '@/views/Default/StudentParentsSessions.vue'
          ),
        meta: {
          title: 'Sessies',
          showFor: [{ role: 'STUDENT' }],
        },
      },
      {
        path: 'ouders/instellingen',
        name: 'StudentParentsSettings',
        component: () =>
          import(
            /* webpackChunkName: "PrivateStudent" */ '@/views/Default/StudentParentsSettings.vue'
          ),
        meta: {
          title: 'Instellingen',
          showFor: [
            {
              role: 'STUDENT',
            },
          ],
        },
      },
      {
        path: 'faq',
        name: 'StudentFaq',
        component: () =>
          import(
            /* webpackChunkName: "PrivateStudent" */ '@/views/Default/StudentFaq.vue'
          ),
        meta: {
          title: 'FAQ',
          showFor: [{ role: 'STUDENT' }],
          hideFromMenu: true,
          // TODO show this route in menu, when FAQ feature is ready
        },
      },
      {
        path: 'management',
        name: 'ResourceManagement',
        meta: {
          title: 'Beheer',
          hideFor: [
            {
              role: 'STUDENT',
            },
          ],
        },
      },
      /** *****************
       *
       *  STUDENTS
       *
       ***************** */
      {
        path: 'management/students',
        name: 'ResourceManagementStudents',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Default/Students.vue'
          ),
        meta: {
          title: 'Leerlingen',
          hideFor: [
            {
              role: 'SCHOOL_DISTRICT_MANAGER',
            },
            {
              context: 'PARTNER',
            },
            {
              role: 'ADMIN',
              context: 'SCHOOL_DISTRICT',
            },
          ],
        },
      },

      {
        path: 'management/student-add',
        name: 'ResourceManagementStudentAdd',
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Default/StudentAdd.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'Leerling toevoegen',
        },
      },
      {
        path: 'management/students-bulk-import',
        name: 'ResourceManagementStudentsBulkImport',
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Default/StudentsBulkImport.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'Leerling toevoegen',
        },
      },
      {
        path: 'management/students-import',
        name: 'ResourceManagementStudentsImport',
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Online/StudentsImport.vue'
          ),
        meta: {
          hideFromMenu: true,
          title: 'Leerlingen importeren',
        },
      },
      {
        path: 'management/students/:studentId',
        name: 'ResourceManagementStudentsStudent',
        component: () =>
          import(
            /* webpackChunkName: "Students" */ '@/views/Default/Student.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementStudentsStudentData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementStudentsStudentData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentData.vue'
              ),
            meta: {
              title: 'Gegevens',
              hideFor: [{ role: 'COACH' }],
            },
          },
          {
            path: 'current-paths',
            name: 'ResourceManagementStudentsStudentCurrentPaths',
            props: true,
            redirect() {
              return {
                name: 'ResourceManagementStudentsStudentPaths',
                query: { currentPath: true },
              };
            },
            meta: {
              title: 'Huidig Traject',
              showFor: [
                {
                  role: 'COACH',
                },
              ],
            },
          },
          {
            path: 'paths',
            name: 'ResourceManagementStudentsStudentPaths',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentPaths.vue'
              ),
            meta: {
              title: 'Alle trajecten',
              showFor: [
                {
                  role: 'COACH',
                },
              ],
            },
          },
          {
            path: 'paths/:path',
            name: 'ResourceManagementStudentsStudentPath',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/Path.vue'
              ),
            props: true,
            meta: {
              hideFromMenu: true,
            },
            children: [
              {
                path: 'overview',
                name: 'ResourceManagementStudentsStudentPathOverview',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/Default/PathOverview.vue'
                  ),
                meta: {
                  title: 'Overzicht traject',
                  showFor: [{ role: 'COACH' }],
                },
              },
              {
                path: 'sessions/:sessionId',
                name: 'ResourceManagementStudentsStudentPathTrainingsTraining',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/Default/SessionData.vue'
                  ),
                meta: {
                  title: 'Sessie',
                  hideFromMenu: true,
                  showFor: [{ role: 'COACH' }],
                },
              },
              {
                path: 'cognition',
                name: 'ResourceManagementStudentsStudentPathCognition',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/Default/Cognition.vue'
                  ),
                meta: {
                  title: 'Cognitie',
                  showFor: [
                    {
                      role: 'COACH',
                    },
                  ],
                },
              },
              {
                path: 'test-results',
                name: 'ResourceManagementStudentsStudentPathTestResults',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/Default/TestResults.vue'
                  ),
                meta: {
                  title: 'Toetsresultaten',
                  showFor: [
                    {
                      role: 'COACH',
                    },
                  ],
                },
              },
              {
                path: 'problem-types',
                name: 'ResourceManagementStudentsStudentPathProblemTypes',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/Default/PathProblemTypes.vue'
                  ),
                meta: {
                  title: 'Problematiek',
                  showFor: [
                    {
                      role: 'COACH',
                    },
                  ],
                },
              },
              {
                path: 'journal',
                name: 'ResourceManagementStudentsStudentPathJournal',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/Default/Journal/Journal.vue'
                  ),
                meta: {
                  hideFromMenu: true,
                  title: 'Trajectjournaal',
                  showFor: [{ role: 'COACH' }],
                },
              },
              {
                path: 'path-settings',
                name: 'ResourceManagementStudentsStudentPathPathSettings',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/PathSettings.vue'
                  ),
                meta: {
                  title: 'Trajectinstellingen',
                  showFor: [{ role: 'COACH' }],
                },
              },
              {
                path: 'add-test-result',
                name: 'ResourceManagementStudentsStudentPathAddTestResult',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/Default/AddTestResult/AddTestResult.vue'
                  ),
                meta: {
                  title: 'Toetsresultaat Toevoegen',
                  hideFromMenu: true,
                  hideFromSideMenu: true,
                  hideTopMenu: true,
                  showFor: [
                    {
                      role: 'COACH',
                    },
                  ],
                },
              },
              {
                path: 'add-diagnose',
                name: 'ResourceManagementStudentsStudentPathAddDiagnose',
                props: true,
                component: () =>
                  import(
                    /* webpackChunkName: "Students" */ '@/views/Default/AddDiagnose.vue'
                  ),
                meta: {
                  title: 'Toetsresultaat Toevoegen',
                  hideFromMenu: true,
                  hideFromSideMenu: true,
                  hideTopMenu: true,
                  showFor: [
                    {
                      role: 'COACH',
                    },
                  ],
                },
              },
            ],
          },

          {
            path: 'progress',
            name: 'ResourceManagementStudentsStudentProgress',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentProgress.vue'
              ),
            meta: {
              title: 'Voortgang',
              showFor: [
                {
                  role: 'COACH',
                },
              ],
            },
          },

          {
            path: 'journal',
            name: 'ResourceManagementStudentsStudentJournal',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentJournal.vue'
              ),
            meta: {
              title: 'Journaal',
              showFor: [{ role: 'COACH' }, { role: 'MANAGER' }],
            },
          },
          {
            path: 'settings',
            name: 'ResourceManagementStudentsStudentSettings',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentSettings.vue'
              ),
            meta: {
              title: 'Instellingen',
            },
          },
          {
            path: 'assigned-cards',
            name: 'ResourceManagementStudentsStudentAssignedCards',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentAssignedCards.vue'
              ),
            meta: {
              title: 'Huiswerk',
              showFor: [{ role: 'COACH' }],
            },
          },
          {
            path: 'edit',
            name: 'ResourceManagementStudentsStudentEdit',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentAdd.vue'
              ),
            meta: {
              title: 'Gegevens bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name: 'ResourceManagementStudentsStudentEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
          {
            path: 'move-student',
            name: 'ResourceManagementStudentsStudentMove',
            component: () =>
              import(
                /* webpackChunkName: "Students" */ '@/views/Default/StudentMove.vue'
              ),
            meta: {
              title: 'Leerling verhuizen',
              hideFromMenu: true,
            },
          },
        ],
      },

      /** *****************
       *
       *  PATH (Traject)
       *
       ***************** */

      /** *****************
       *
       *  COURSEGROUPS
       *
       ***************** */
      {
        path: 'management/coursegroups',
        name: 'ResourceManagementCourseGroups',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroups.vue'
          ),
        meta: {
          title: 'Groepen',
          hideFor: [
            {
              role: 'SCHOOL_DISTRICT_MANAGER',
            },
            {
              context: 'ADMIN',
            },
            {
              context: 'PARTNER',
            },
          ],
        },
      },
      {
        path: 'management/coursegroup-add',
        name: 'ResourceManagementCourseGroupAdd',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupAddEdit.vue'
          ),
        meta: {
          title: 'Cursusgroep toevoegen',
          hideFromMenu: true,
        },
      },
      {
        path: 'management/coursegroups/:courseGroupId',
        name: 'ResourceManagementCourseGroupsCourseGroup',
        component: () =>
          import(
            /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroup.vue'
          ),
        props: true,
        meta: {
          title: 'Cursusgroepen',
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementCourseGroupsCourseGroupStudents',
        },
        children: [
          {
            path: 'students',
            name: 'ResourceManagementCourseGroupsCourseGroupStudents',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupStudents.vue'
              ),
            meta: {
              hideFromMenu: false,
              title: 'Leerlingen',
            },
          },
          {
            path: 'coaches',
            name: 'ResourceManagementCourseGroupsCourseGroupCoaches',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupCoaches.vue'
              ),
            meta: {
              hideFromMenu: false,
              hideFor: [
                {
                  role: 'COACH',
                },
              ],
              title: 'Begeleiders',
            },
          },
          {
            path: 'homework',
            name: 'ResourceManagementCourseGroupsCourseGroupHomeWork',
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupHomeWork.vue'
              ),
            props: true,
            meta: {
              title: 'Huiswerk',
              showFor: [
                {
                  role: 'COACH',
                },
              ],
            },
          },
          {
            path: 'edit',
            name: 'ResourceManagementCourseGroupsCourseGroupEdit',
            component: () =>
              import(
                /* webpackChunkName: "Coursegroups" */ '@/views/Default/CourseGroupAddEdit.vue'
              ),
            props: true,
            meta: {
              hideFor: [
                {
                  role: 'COACH',
                },
              ],
              title: 'Beheren',
            },
          },
        ],
      },
      /** *****************
       *
       *  COACHES
       *
       ***************** */
      {
        path: 'management/coaches',
        name: 'ResourceManagementCoaches',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "Coaches" */ '@/views/Default/Coaches.vue'
          ),
        meta: {
          title: 'Begeleiders',
          hideFor: [
            {
              role: 'COACH',
            },
            {
              role: 'SCHOOL_DISTRICT_MANAGER',
            },
            {
              context: 'PARTNER',
            },
            {
              role: 'ADMIN',
              context: 'SCHOOL_DISTRICT',
            },
          ],
        },
      },
      {
        path: 'management/coach-add',
        name: 'ResourceManagementCoachAdd',
        component: () =>
          import(
            /* webpackChunkName: "Coaches" */ '@/views/Default/CoachAdd.vue'
          ),
        meta: {
          hideFromMenu: true,
          title: 'Coach Toevoegen',
        },
      },
      {
        path: 'management/coaches/:coachId',
        name: 'ResourceManagementCoachesCoach',
        component: () =>
          import(/* webpackChunkName: "Coaches" */ '@/views/Default/Coach.vue'),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementCoachesCoachData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementCoachesCoachData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/CoachData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'coursegroups',
            name: 'ResourceManagementCoachesCoachGroups',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/CoachCourseGroups.vue'
              ),
            meta: {
              title: 'Cursusgroepen',
            },
          },
          {
            path: 'activity',
            name: 'ResourceManagementCoachesActivity',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/CoachActivity.vue'
              ),
            meta: {
              title: 'Activiteit',
            },
          },

          {
            path: 'edit',
            name: 'ResourceManagementCoachesCoachEdit',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/CoachAdd.vue'
              ),
            meta: {
              title: 'Bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name: 'ResourceManagementCoachesCoachEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "Coaches" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
        ],
      },
      /** *****************
       *
       *  SCHOOLS
       *
       ***************** */
      {
        path: 'management/schools',
        name: 'ResourceManagementSchools',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "Schools" */ '@/views/Default/Schools.vue'
          ),
        meta: {
          title: 'Scholen',
          showFor: [
            {
              role: 'ADMIN',
              context: 'ADMIN',
            },
            {
              role: 'SCHOOL_DISTRICT_MANAGER',
            },
            {
              context: 'PARTNER',
            },
            {
              role: 'ADMIN',
              context: 'SCHOOL_DISTRICT',
            },
          ],
        },
      },
      {
        path: 'management/manage/:manageMethod/:organisationType',
        name: 'ResourceManagementOrganisationAdd',
        component: () =>
          import(
            /* webpackChunkName: "Schools" */ '@/views/Default/OrganisationAddEdit.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'Resource Toevoegen',
        },
      },
      {
        path: 'management/schools/:organisationId',
        name: 'ResourceManagementSchoolsSchool',
        component: () =>
          import(/* webpackChunkName: "Schools" */ '@/views/School/School.vue'),
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'School',
        },
        redirect: {
          name: 'ResourceManagementSchoolsSchoolData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementSchoolsSchoolData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/School/SchoolData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: ':manageMethod/:organisationType/',
            name: 'ResourceManagementSchoolsSchoolAddEdit',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/OrganisationAddEdit.vue'
              ),
            meta: {
              title: 'Bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'managers',
            name: 'ResourceManagementSchoolsSchoolManagers',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/SchoolManagers.vue'
              ),
            meta: {
              title: 'Schoolbeheerders',
              showFor: [
                {
                  context: 'ADMIN',
                },
                {
                  context: 'SCHOOL_DISTRICT',
                },
                {
                  context: 'PARTNER',
                },
              ],
            },
          },
          {
            path: 'access-requests',
            name: 'ResourceManagementSchoolAccessRequest',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/Default/AccessRequests.vue'
              ),
            meta: {
              title: 'Toegangsverzoeken',
              showFor: [
                {
                  role: 'ADMIN',
                  context: 'ADMIN',
                },
              ],
            },
          },
          {
            path: 'settings',
            name: 'ResourceManagementSchoolsSchoolSettings',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Schools" */ '@/views/School/SchoolSettings.vue'
              ),
            meta: {
              title: 'Instellingen',
              showFor: [
                {
                  role: 'ADMIN',
                  context: 'ADMIN',
                },
                {
                  context: 'PARTNER',
                },
              ],
            },
          },
        ],
      },
      /** *****************
       *
       *  SCHOOL MANAGERS
       *
       ***************** */
      {
        path: 'management/managers',
        name: 'ResourceManagementManagers',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SchoolManagers" */ '@/views/Default/SchoolManagers.vue'
          ),
        meta: {
          title: 'Schoolbeheerders',

          showFor: [
            {
              role: 'MANAGER',
            },
            {
              role: 'ADMIN',
              context: 'SCHOOL',
            },
          ],
        },
      },
      {
        path: 'management/managers/:managerId',
        name: 'ResourceManagementManagersManager',
        component: () =>
          import(
            /* webpackChunkName: "SchoolManagers" */ '@/views/Default/Manager.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementManagersManagerData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementManagersManagerData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolManagers" */ '@/views/Default/ManagerData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'manage',
            name: 'ResourceManagementManagerEdit',
            component: () =>
              import(
                /* webpackChunkName: "SchoolManagers" */ '@/views/Default/ManagerAddEdit.vue'
              ),
            meta: {
              title: 'Schoolbeheerder bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name: 'ResourceManagementManagerEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "SchoolManagers" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
        ],
      },
      {
        path: 'manager-add',
        name: 'ResourceManagementManagerAdd',
        component: () =>
          import(
            /* webpackChunkName: "SchoolManagers" */ '@/views/Default/ManagerAddEdit.vue'
          ),
        meta: {
          title: 'Schoolbeheerder toevoegen',
          hideFromMenu: true,
        },
      },
      /** *****************
       *
       *  SCHOOLDISTRICTS
       *
       ***************** */
      {
        path: 'management/manage/:manageMethod/:organisationType',
        name: 'ResourceManagementSchooldistrictAdd',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/OrganisationAddEdit.vue'
          ),
        meta: {
          hideFromMenu: true,
          title: 'Schooldistrict Toevoegen',
        },
      },
      {
        path: 'management/schooldistricts',
        name: 'ResourceManagementSchooldistricts',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/Schooldistricts.vue'
          ),
        meta: {
          title: 'Schooldistricten',
          showFor: [
            {
              role: 'ADMIN',
              context: 'ADMIN',
            },
            {
              context: 'PARTNER',
            },
          ],
        },
      },
      {
        path: 'management/schooldistricts/:organisationId',
        name: 'ResourceManagementSchooldistrictsSchooldistrict',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistricts" */ '@/views/Schooldistricts/Schooldistrict.vue'
          ),
        props: true,
        meta: {
          title: 'Schooldistricten',
          hideFromMenu: true,
        },
        redirect: {
          name: 'ResourceManagementSchooldistrictsSchooldistrictData',
        },
        children: [
          {
            path: 'data',
            name: 'ResourceManagementSchooldistrictsSchooldistrictData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Schooldistricts/SchooldistrictData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          // {
          //   path: 'licenses',
          //   name: 'ResourceManagementSchooldistrictsSchooldistrictLicenses',
          //   props: true,
          //   component: () => import(/* webpackChunkName: "SchoolDistricts" */ '@/views/Default/ResourceLicenses.vue'),
          //   meta: {
          //     title: 'Licenties'
          //   }
          // },
          {
            path: ':manageMethod/:organisationType',
            name: 'ResourceManagementSchooldistrictsSchooldistrictEdit',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/OrganisationAddEdit.vue'
              ),
            meta: {
              title: 'Bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'schools',
            name: 'ResourceManagementSchooldistrictsSchooldistrictSchools',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/SchooldistrictSchools.vue'
              ),
            meta: {
              title: 'Scholen',
            },
          },
          {
            path: 'schooldistrict-managers',
            name:
              'ResourceManagementSchooldistrictsSchooldistrictSchooldistrictManagers',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Default/SchooldistrictManagers.vue'
              ),
            meta: {
              title: 'Schooldistrictbeheerders',
            },
          },
          {
            path: 'settings',
            name: 'ResourceManagementSchooldistrictsSchooldistrictSettings',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistricts" */ '@/views/Schooldistricts/SchooldistrictSettings.vue'
              ),
            meta: {
              title: 'Instellingen',
              showFor: [
                {
                  role: 'ADMIN',
                  context: 'ADMIN',
                },
                {
                  context: 'PARTNER',
                },
              ],
            },
          },
        ],
      },
      /** *****************
       *
       *  SCHOOL DISTRICT MANAGERS
       *
       ***************** */
      {
        path: 'management/schooldistrict-managers',
        name: 'ResourceManagementDistrictManagers',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManagers.vue'
          ),
        meta: {
          title: 'Schooldistrictbeheerders',

          showFor: [
            {
              role: 'ADMIN',
              context: 'SCHOOL_DISTRICT',
            },
            {
              context: 'SCHOOL_DISTRICT',
            },
          ],
        },
      },
      {
        path: 'management/schooldistrict-managers/:schooldistrictManagerId',
        name: 'ResourceManagementSchooldistrictManagersSchooldistrictManager',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManager.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
        },
        redirect: {
          name:
            'ResourceManagementSchooldistrictManagersSchooldistrictManagerData',
        },
        children: [
          {
            path: 'data',
            name:
              'ResourceManagementSchooldistrictManagersSchooldistrictManagerData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManagerData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'manage',
            name:
              'ResourceManagementSchooldistrictManagersSchooldistrictManagerEdit',
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManagerAddEdit.vue'
              ),
            meta: {
              title: 'Schoolbeheerder bewerken',
              hideFromMenu: true,
            },
          },
          {
            path: 'edit-password',
            name:
              'ResourceManagementSchooldistrictManagersSchooldistrictManagerEditPassword',
            component: () =>
              import(
                /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/EditPassword.vue'
              ),
            meta: {
              title: 'Wachtwoord wijzigen',
              hideFromMenu: true,
            },
          },
        ],
      },
      {
        path: 'schooldistrict-manager-add',
        name:
          'ResourceManagementSchooldistrictManagersSchooldistrictManagerAdd',
        component: () =>
          import(
            /* webpackChunkName: "SchoolDistrictManagers" */ '@/views/Default/SchooldistrictManagerAddEdit.vue'
          ),
        meta: {
          title: 'Schoolbeheerder toevoegen',
          hideFromMenu: true,
        },
      },
      /** *****************
       *
       *  ARCHIVED USERS
       *
       ***************** */
      {
        path: 'management/archived-users',
        name: 'ResourceManagementArchivedUsers',
        component: () =>
          import(
            /* webpackChunkName: "ArchivedUsers" */ '@/views/Default/ArchivedUsers.vue'
          ),
        meta: {
          title: 'Gearchiveerde Gebruikers',
          showFor: [
            { role: 'MANAGER' },
            { role: 'ADMIN', context: 'ADMIN' },
            { role: 'ADMIN', context: 'SCHOOL' },
          ],
        },
      },

      /** *****************
       *
       *  ADMINISTRATION
       *
       ***************** */
      {
        path: 'management/dictionary',
        name: 'ResourceManagementDictionary',
        props: true,
        component: () =>
          import(
            /* webpackChunkName: "Dictionary" */ '@/views/Default/Dictionary.vue'
          ),
        meta: {
          title: 'Woordenboek',
          showFor: [{ role: 'ADMIN' }],
        },
      },
      {
        path: 'management/dictionary-add',
        name: 'ResourceManagementDictionaryAdd',
        component: () =>
          import(
            /* webpackChunkName: "Dictionary" */ '@/views/Default/DictionaryAdd.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'Woord definiëren',
        },
      },
      {
        path: 'management/dictionary-edit/:wordId',
        name: 'ResourceManagementDictionaryEdit',
        component: () =>
          import(
            /* webpackChunkName: "Dictionary" */ '@/views/Default/DictionaryEdit.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'Woord aanpassen',
        },
      },
      {
        path: 'management/dictionary-maintainers',
        name: 'ResourceManagementDictionaryMaintainers',
        component: () =>
          import(
            /* webpackChunkName: "Dictionary" */ '@/views/Default/DictionaryMaintainers.vue'
          ),
        props: true,
        meta: {
          hideFromMenu: true,
          title: 'Woordenboek beheren',
        },
      },

      {
        path: 'administration',
        name: 'Administration',
        redirect: {
          name: 'AdministrationOrganisation',
        },
        meta: {
          title: 'Administratie',
          badge: 'amountOfAccessRequest',
          hideFor: [
            {
              role: 'COACH',
            },
            {
              role: 'STUDENT',
            },
          ],
        },
      },
      {
        path: 'administration/organisation',
        name: 'AdministrationOrganisation',
        component: () =>
          import(
            /* webpackChunkName: "Administration" */ '@/views/Default/Resource.vue'
          ),
        redirect: {
          name: 'AdministrationOrganisationSchoolData',
        },
        props: true,
        meta: {
          title: 'Organisatie',
          badge: 'amountOfAccessRequest',
          hideFor: [{ role: 'COACH' }],
        },
        children: [
          {
            path: 'data',
            name: 'AdministrationOrganisationSchoolData',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Administration" */ '@/views/Default/ResourceData.vue'
              ),
            meta: {
              title: 'Gegevens',
            },
          },
          {
            path: 'access-requests',
            name: 'AdministrationOrganisationAccessRequest',
            props: true,
            component: () =>
              import(
                /* webpackChunkName: "Administration" */ '@/views/Default/AccessRequests.vue'
              ),
            meta: {
              title: 'Toegangsverzoeken',
              badge: 'amountOfAccessRequest',
              hideFor: [
                {
                  context: 'SCHOOL_DISTRICT',
                },
                {
                  context: 'PARTNER',
                },
              ],
            },
          },
        ],
      },

      /** *****************
       *
       *  KNOWLEDGE BASE
       *
       ***************** */

      {
        path: 'knowledge-base',
        name: 'ResourceKnowledgeBase',
        redirect: 'knowledge-base/techniques',

        // children: [
        //   {
        //     path: 'methods',
        //     name: 'ResourceKnowledgeBaseMethods',
        //     component: () =>
        //       import(
        //         /* webpackChunkName: "Misc" */ '@/views/Default/Methods.vue'
        //       ),
        //     meta: {
        //       title: 'Technieken',
        //       hideFromMenu: true,
        //     },
        //   },

        // ],
        meta: {
          title: 'Kennisbank',
          showFor: [
            {
              role: 'COACH',
            },
          ],
        },
      },
      {
        path: 'knowledge-base/:route',
        name: 'ResourceKnowledgeBaseRoute',
        component: () =>
          import(
            /* webpackChunkName: "Misc" */ '@/views/Default/KnowledgeBase.vue'
          ),
        meta: {
          title: 'Kennisbank',
          hideFromMenu: true,
          showFor: [
            {
              role: 'COACH',
            },
          ],
        },
      },

      /** *****************
       *
       *  CARDS
       *
       ***************** */

      {
        path: 'cards',
        name: 'ResourceCards',
        component: () =>
          import(/* webpackChunkName: "Misc" */ '@/views/Default/Cards.vue'),
        meta: {
          title: 'Kaartenbak',
          showFor: [{ role: 'COACH' }, { role: 'STUDENT' }],
        },
      },

      /** *****************
       *
       *  SETTINGS
       *
       ***************** */

      {
        path: 'settings',
        name: 'ResourceSettings',
        component: () =>
          import(
            /* webpackChunkName: "Misc" */ '@/views/Default/ResourceSettings.vue'
          ),
        meta: {
          title: 'Instellingen',
          hideFor: [{ role: 'STUDENT' }, { role: 'COACH' }],
          showFor: [{ context: 'SCHOOL' }],
        },
      },
      {
        path: 'misc',
        name: 'Miscellaneous',
        component: () =>
          import(
            /* webpackChunkName: "Misc" */ '@/views/Default/Miscellaneous.vue'
          ),
        meta: {
          title: 'Overig',
          showFor: [{ context: 'ADMIN' }],
        },
      },
    ],
  },

  /** *****************
   *
   *  ERROR PAGES
   *
   ***************** */
  {
    path: '*',
    name: 'PageNotFound',
    component: () =>
      import(/* webpackChunkName: "Misc" */ '@/views/PageNotFound.vue'),
    meta: {
      allowUnauthorized: true,
    },
  },

  {
    path: '/not-allowed',
    name: 'NotAllowed',
    component: () =>
      import(/* webpackChunkName: "Misc" */ '@/views/NotAllowed.vue'),
    meta: {
      allowUnauthorized: true,
    },
  },
];

const router = new VueRouter({
  routes,
  linkActiveClass: 's_active',
});

router.beforeEach(async (to, from, next) => {
  // scroll to top of page after navigation
  if (window.scroll) {
    window.scroll({
      top: 0,
      left: 0,
    });
  }

  // always allow routes that do not need authorization
  if (to.meta.allowUnauthorized) return next();

  const token = getToken();

  // if no JWT is found redirect to login
  if (!token) {
    return next({
      name: 'Login',
    });
  }
  const role = getRole()?.toLowerCase();

  // redirect based on role
  if (to.name === 'Home' && token) {
    if (getRole() === 'ADMIN') {
      next({
        name: 'Resource',
        params: {
          role,
          resourceType: 'admin',
          resourceId: 'default',
        },
      });
    }

    next({
      name: 'Resource',
      params: {
        role,
        resourceType: getResourceType()?.toLowerCase(),
        resourceId: getResourceId(),
      },
    });
  }

  // If JWT role doesn't match with route, redirect to Home for correct front-end environment
  if (getRole() && to.params.role && to.params.role !== role) {
    console.warn('ROLE IN JWT DOESNT MATCH WITH ROUTE');
    return next({
      name: 'Home',
    });
  }

  return next();
});

export default router;
