import Shepherd from 'shepherd.js';
import { createTour } from './tour';
import { TOUR_KEYS } from './tourkeys';

export class TourStarter {
  constructor(tourKey, role, onCanceled, onClose, onCompleted) {
    this.tourKey = tourKey;
    this.tourKeys = TOUR_KEYS;
    this.role = role;
    this.onCanceled = onCanceled;
    this.onClose = onClose;
    this.onCompleted = onCompleted;
    this.tourRef = null;
  }

  static getTourKeyForRole(role, keys = []) {
    const validKeys = keys.filter((key) => key.startsWith(role));
    return validKeys[0];
  }

  getTour() {
    if (!this.tourKey) return console.log('No tour key is set');
    return createTour(this.tourKey);
  }

  cancel() {
    if (this.tourRef) {
      this.tour.cancel();
    }
  }

  async start() {
    const tour = this.getTour();
    this.tourRef = this.tour;
    const onCanceledCallback = this.onCanceled;
    const onCloseCallback = this.onClose;
    const onCompletedCallback = this.onCompleted;

    Shepherd.once('complete', async () => {
      await onCompletedCallback();
      await onCloseCallback();
    });

    Shepherd.once('cancel', async () => {
      await onCanceledCallback();
      await onCloseCallback();
    });

    tour.start();
  }
}
