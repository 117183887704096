<template>
  <div
    class="sb_button"
    :button-type="buttonType"
    :icon-animation-direction="iconAnimationDirection"
    :size="size"
    :icon-size="iconSize"
    :disabled="disabled"
    :class="{
      'v_icon-only': iconOnly,
      s_loading: loading,
    }"
  >
    <span class="sb_button_wrapper">
      <slot></slot>
      <sb-loading-spinner v-if="loading" :size="getIconSize()" />
    </span>
  </div>
</template>

<script>
import SbLoadingSpinner from '@/components/SbLoadingSpinner.vue';

export default {
  components: {
    SbLoadingSpinner,
  },
  props: {
    buttonType: {
      default: 'primary',
      validator: (value) =>
        [
          'primary',
          'primary-gradient',
          'light',
          'light-black',
          'black',
          'red',
          'green',
          'white',
        ].includes(value),
    },
    iconAnimationDirection: {
      default: 'none',
      validator: (value) =>
        ['none', 'top', 'right', 'bottom', 'left'].includes(value),
    },
    iconSize: {
      default: 'regular',
      validator: (value) => ['regular', 'large', 'largest'].includes(value),
    },
    size: {
      default: 'regular',
      validator: (value) => ['small', 'regular', 'large'].includes(value),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      iconOnly: false,
    };
  },
  mounted() {
    const defaultSlot = this.$slots.default;

    if (typeof defaultSlot !== 'undefined') {
      if (defaultSlot.length === 1 && defaultSlot[0].componentOptions) {
        if (
          ['SbIcon', 'sb-icon', 'Icon', 'icon'].includes(
            defaultSlot[0].componentOptions.tag,
          )
        ) {
          this.iconOnly = true;
        }
      }
    } else {
      this.iconOnly = true;
    }
  },
  methods: {
    getIconSize() {
      let size;
      if (this.size === 'large') {
        size = '1.4rem';
      }
      if (this.size === 'regular') {
        size = '1.2rem';
      }
      if (this.size === 'small') {
        size = '.95rem';
      }
      return size;
    },
  },
};
</script>

<style lang="scss" scoped>
//
// IMPORT BUTTON TYPES
//

@import '@/styles/buttons/primary.scss';
@import '@/styles/buttons/light.scss';
@import '@/styles/buttons/light-black.scss';
@import '@/styles/buttons/black.scss';
@import '@/styles/buttons/white.scss';
@import '@/styles/buttons/red.scss';
@import '@/styles/buttons/green.scss';
// @import '@/styles/buttons/yellow.scss';

.sb_button {
  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin: 0;
  background: none;
  outline: none;
  border-radius: 8px;
  border: none;
  text-align: left;
  transition: opacity 0.3s ease;
  font-size: 1rem;
  padding: 1rem 1rem;
  min-height: rem-calc(40);
  line-height: 1;
  font-weight: bold;
  user-select: none;
  text-decoration: none;
  transition: color 0.2s ease, background-color 0.2s ease,
    border-color 0.3s ease;
  cursor: pointer;

  @include breakpoint($medium-portrait) {
    font-size: 1.1rem;
    padding: 0.8rem 1.3rem;
    min-height: rem-calc(48);
  }

  //
  //  SVG
  //
  svg {
    font-size: 1.1em;
    transition: transform 0.2s ease;
  }

  //
  //  WRAPPER
  //
  &_wrapper {
    display: inline-grid;
    grid-auto-flow: column;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 1rem;
  }

  //
  //  SIZE - ICON-SIZE
  //

  &[icon-size='regular'] {
  }
  &[icon-size='large'] {
    svg {
      transform: scale(1.3);
    }
  }
  &[icon-size='largest'] {
    svg {
      transform: scale(1.5);
    }
  }

  //
  //  SIZE - SMALL
  //

  &[size='small'] {
    padding: 0.5rem 0.6rem;
    min-height: auto;

    &.s_loading {
      // padding: 0.832rem 1rem;
    }
    .sb_button_wrapper {
      grid-gap: 0.5rem;
    }
  }

  //
  //  SIZE - LARGE
  //

  &[size='large'] {
    padding: 1rem 1.6rem;
    min-height: auto;
    font-size: 1.3rem;

    &.s_loading {
      // padding: 0.832rem 1rem;
    }
    .sb_button_wrapper {
      grid-gap: 0.8rem;
    }
  }

  //
  //  NAKED
  //
  &[naked] {
    &[collapsed] {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    &[collapsed-left] {
      padding-left: 0 !important;
    }
    &[collapsed-right] {
      padding-right: 0 !important;
    }
  }

  //
  //  DISABLED
  //
  &[disabled] {
    cursor: not-allowed;
  }

  //
  //  EXPAND
  //
  &[expand] {
    width: 100%;
    justify-content: center;
  }

  //
  //  EXPAND ON SMALL
  //
  &[expand-on-small] {
    @media screen and (max-width: rem-calc($small-max)) {
      width: 100%;
      justify-content: center;
    }
  }

  //
  //  EXPAND MEDIUM PORTRAIT DOWN
  //
  &[expand-medium-portrait-down] {
    @media screen and (max-width: rem-calc($medium-portrait-max)) {
      width: 100%;
      justify-content: center;
    }
  }

  //
  // UNRESPONSIVE
  //
  &[unresponsive] {
    pointer-events: none;
  }

  //
  //  ROUND
  //
  &[round] {
    border-radius: 99rem;
  }

  //
  //  STATE - LOADING
  //
  &.s_loading {
    pointer-events: none;

    svg {
      display: none;
    }
  }

  //
  //  VERSION - ICON ONLY
  //
  &.v_icon-only {
    padding: 1rem;
    &[size='small'] {
      padding: 0.44rem !important;
      min-height: auto;
    }
  }

  //
  //  DISABLED
  //

  &:disabled {
    pointer-events: none;
  }
}

//
//  ON HOVER - ICON ANIMATION
//
a:hover .sb_button,
a:focus .sb_button,
button:focus .sb_button,
button:hover .sb_button {
  //  ON HOVER - ICON ANIMATION - UP
  &[icon-animation-direction='up'] {
    svg {
      transform: translateY(-0.4rem);
    }
  }

  //  ON HOVER - ICON ANIMATION - RIGHT
  &[icon-animation-direction='right'] {
    svg {
      transform: translateX(0.4rem);
    }
  }

  //  ON HOVER - ICON ANIMATION - BOTTOM
  &[icon-animation-direction='down'] {
    svg {
      transform: translateY(0.4rem);
    }
  }

  //  ON HOVER - ICON ANIMATION - LEFT
  &[icon-animation-direction='left'] {
    svg {
      transform: translateX(-0.4rem);
    }
  }
}
</style>
