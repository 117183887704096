export const WordRelations = {
  STEM: 'STEM',
  PLURAL: 'PLURAL',
  DIMINUTIVE: 'DIMINUTIVE',
  DIMINUTIVEPLURAL: 'DIMINUTIVEPLURAL',
  CONJUGATION: 'CONJUGATION',
  DERIVATIVE: 'DERIVATIVE',
};

/**
 * Get a human readable label for the corresponding `WordRelations` member.
 * @param {string} type
 * @returns {string} label
 */
export function getWordRelationsLabel(type) {
  switch (type) {
    case WordRelations.CONJUGATION:
      return 'vervoeging';
    case WordRelations.DIMINUTIVE:
      return 'verkleinwoord enkelvoud';
    case WordRelations.DIMINUTIVEPLURAL:
      return 'verkleinwoord meervoud';
    case WordRelations.PLURAL:
      return 'meervoud';
    case WordRelations.STEM:
      return 'stam';
    case WordRelations.DERIVATIVE:
      return 'afgeleide';
    default:
      throw new Error(`Unknown WordRelations type ${type}`);
  }
}

export const wordRelationsMixin = {
  data: () => ({ WordRelations }),
  methods: { getWordRelationsLabel },
};
