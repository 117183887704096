import { Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';

export default function ApolloErrorHandler({
  errorHandler,
  isUnauthenticatedError,
  fetchNewAccessToken,
  authorizationHeaderKey,
}) {
  return onError((ctx) => {
    if (errorHandler) errorHandler(ctx);
    const { graphQLErrors, networkError, forward, operation } = ctx;
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        if (isUnauthenticatedError(error)) {
          return new Observable((observer) => {
            fetchNewAccessToken()
              .then((newAccessToken) => {
                if (!newAccessToken) {
                  throw new Error('Unable to fetch new access token');
                }

                operation.setContext(({ headers = {} }) => {
                  delete headers.authorization;
                  delete headers.Authorization;
                  return {
                    headers: {
                      ...headers,
                      [authorizationHeaderKey]: newAccessToken || undefined,
                    },
                  };
                });
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                forward(operation).subscribe(subscriber);
              })
              .catch((fetchError) => {
                observer.error(fetchError);
              });
          });
        }
      }
    } else if (networkError) {
      console.log(networkError);
    }
  });
}
