import tourDataMixin from '@/mixins/tourDataMixin';
import { TourStarter } from '@/shepherd/TourStarter';
import { mapGetters } from 'vuex';
import { mapActions } from 'vuex';
import { getRawToken } from '@/lib/user-context-manager';

export default {
  mixins: [tourDataMixin],
  data() {
    return {
      onCompletedIndex: 0,
      previousTourKey: null,
      showSkipTourModal: false,
      tourStarter: null,
      setShowSkip: () => {},

      // From tourDataMixin
      // onboardingTours: {},
    };
  },
  computed: {
    ...mapGetters('onboarding', [
      'getCurrentTourKey',
      'queueLength',
      'onboardingTours',
    ]),
  },
  methods: {
    ...mapActions('onboarding', [
      'enqueueTour',
      'dequeueTour',
      'clearTourQueue',
      'removeFromTourQueue',
    ]),
    async skipCurrentTour() {
      await this.updateOnboardingSetting(
        this.getCurrentTourKey ?? this.previousTourKey,
        'SKIPPED',
      );
      this.showSkipTourModal = false;
    },

    areConditionsMetForTour() {
      if (!this.getCurrentTourKey) {
        console.log('No tour key is set');
        return;
      }

      if (!this.$user.role) {
        console.log('No role is set');
        return false;
      }

      const tourRole = this.getCurrentTourKey.split('_')[0];
      const correctRole = this.$user.role === tourRole;

      if (!correctRole) {
        console.log(
          `Tour ${this.currentTourKey} is not for role ${this.$user.role}`,
        );
        return false;
      }

      const value = this.onboardingTours[this.getCurrentTourKey];
      const isCompleted = value === 'COMPLETED';
      const isSkipped = value === 'SKIPPED';
      if (isCompleted | isSkipped) {
        this.removeFromTourQueue(this.getCurrentTourKey);
        return false;
      }

      console.log('Conditions are met for tour', this.getCurrentTourKey, {
        status: this.onboardingTours[this.getCurrentTourKey],
      });

      return true;
    },

    async startTour() {
      if (!this.tourStarter) {
        console.log('No tour starter');
        return;
      }

      if (!this.onboardingTours?.hasOwnProperty(this.getCurrentTourKey)) {
        return;
      }

      if (!this.areConditionsMetForTour()) {
        return;
      }

      this.previousTourKey = this.getCurrentTourKey
        ? JSON.parse(JSON.stringify(this.getCurrentTourKey))
        : this.previousTourKey;

      this.tourStarter.start();
    },
  },

  async mounted() {
    if (getRawToken()) {
      await this.getOnboardingSettings();
    }
  },

  watch: {
    getCurrentTourKey: {
      handler(newVal, _) {
        const onCompleted = () => this.onCompletedIndex++;
        const starter = new TourStarter(
          newVal,
          this.$user.role,
          () => (this.showSkipTourModal = true),
          () => {},
          async () => {
            await this.updateOnboardingSetting(newVal, 'COMPLETED');
            onCompleted();
          },
        );
        this.tourStarter = starter;
      },
      immediate: true,
    },
    tourStarter: {
      async handler(newVal, oldVal) {
        if (!newVal) return;

        if (oldVal) {
          // Cleanup
          await oldVal.cancel();
        }

        await this.startTour();
      },
      immediate: true,
    },
    onboardingTours: {
      handler() {
        if (this.getCurrentTourKey !== this.previousTourKey) {
          // Async problem where onboardingsTour update is faster than getCurrentTourKey update
          this.startTour();
        }
      },
      immediate: true,
    },
    $route: {
      handler() {
        this.clearTourQueue();
      },
      immediate: true,
    },
    showSkipTourModal: {
      handler(newVal) {
        // If set to false (i.e. the user has clicked "No, thanks"), then
        // dequeue the tour and start the next one.
        if (!newVal) {
          console.log('Dequeueing tour');
          this.dequeueTour();
        }
      },
      immediate: true,
    },
    onCompletedIndex: {
      handler() {
        this.dequeueTour();
      },
    },
  },
};
