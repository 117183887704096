<template>
  <div
    class="sb_info"
    :class="{ s_inline: inline }"
    :style="{ left: offsetLeft }"
  >
    <dropdown placement="right-start">
      <div class="sb_info_btn-info">
        <sb-icon icon-id="icon_info" />
      </div>
      <dropdown-menu
        slot="list"
        style="width: 300px; padding: 20px"
        class="sb_info_content-slot"
      >
        <slot></slot>
      </dropdown-menu>
    </dropdown>
  </div>
</template>

<script>
export default {
  name: 'SbInfo',

  props: {
    inline: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    offsetLeft() {
      let parent = this.$parent;
      let labelWidth = parent.labelWidth;
      let i = 0;

      while (labelWidth == undefined) {
        parent = parent.$parent;
        labelWidth = parent.labelWidth;
        i++;
      }

      const selfWidth = 16;
      const spacing = 10;

      return -(labelWidth + selfWidth + spacing) + 'px';
    },
  },
};
</script>

<style lang="scss">
.sb_info {
  position: absolute;
  z-index: 4;
  top: 10px;
  line-height: 1.3 !important;
  padding-right: 1rem;

  .ivu-dropdown {
    min-width: 150%;
  }

  //
  // BTN-INFO
  //
  &_btn-info {
    color: $brand-gray;
    transition: color 0.2s ease;
    transform: scale(0.7);

    @media screen and (min-width: 860px) {
      transform: scale(1);
    }

    // BTN-INFO - HOVER
    &:hover {
      color: $brand-dark-gray;
      cursor: pointer;
    }
  }

  //
  // STATE - INLINE
  //
  &.s_inline {
    position: relative;
    left: auto;
    margin: 0 1rem 0 0;
    display: inline-block;
    top: auto;
  }
}
</style>
