<template>
  <div>
    <div
      v-if="!!$store.state.activeWordDefinition"
      class="sb_word-definition-info row-in-4"
    >
      <sb-word-definition-content
        :word-entry="$store.state.activeWordDefinition"
      />
    </div>
  </div>
</template>

<script>
import { wordRelationsMixin } from '@/lib/word-relations';
import SbWordDefinitionContent from './SbWordDefinitionContent.vue';
export default {
  components: { SbWordDefinitionContent },
  mixins: [wordRelationsMixin],
};
</script>

<style lang="scss" scoped>
.sb_word-definition-info {
  max-height: 100vh;
  overflow-y: auto;
  font-size: 1.2rem;
}
</style>
