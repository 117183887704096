<template>
  <div
    class="sb_content-indent"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SbContentIndent'
};
</script>

<style lang="scss" scoped>
  .sb_content-indent {
    position: relative;
    padding: 10px 25px;
  }
</style>
