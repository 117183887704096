import PlatformSettings from '@/graphql/queries/PlatformSettings.gql';

export const platformSettingsMixin = {
  data() {
    return {
      platformSettings: {
        readingComprehension: false,
        dictionary: false,
      },
    };
  },
  apollo: {
    fetchedPlatformSettings: {
      skip() {
        return !this.$user.token;
      },

      update(data) {
        if (!data.platformSettings) return;
        this.platformSettings = data.platformSettings;
      },

      query: PlatformSettings,
    },
  },
};
