export const TOUR_KEYS = {
  // BEHEERDER
  MANAGER_LOGIN: 'MANAGER_LOGIN', // A
  MANAGER_BEHEER: 'MANAGER_BEHEER', // B
  MANAGER_ADD_STUDENT: 'MANAGER_ADD_STUDENT', // C
  MANAGER_IMPORT_STUDENT: 'MANAGER_IMPORT_STUDENT', // D
  MANAGER_COACH: 'MANAGER_COACH', // E
  MANAGER_BEGELEIDER_ADD: 'MANAGER_BEGELEIDER_ADD', // F
  MANAGER_GROUP: 'MANAGER_GROUP', // G
  MANAGER_ADD_GROUP: 'MANAGER_ADD_GROUP', // H
  MANAGER_STUDENT: 'MANAGER_STUDENT', // I

  // BEGELEIDER
  COACH_ACCOUNT: 'COACH_ACCOUNT', // A
  COACH_BEHEER: 'COACH_BEHEER', // B
  COACH_STUDENT: 'COACH_STUDENT', // C
  COACH_RESULTS: 'COACH_RESULTS', // D
  COACH_PROBLEM_DIAGNOSE: 'COACH_PROBLEM_DIAGNOSE', // E
  COACH_CURRENT_TRACK: 'COACH_CURRENT_TRACK', // F
  COACH_CARD: 'COACH_CARD', // G
};
