<template>
  <div class="sb_title" :size="size" :class="{ 'v_add-margin': addMargin }">
    <component :is="type" v-if="text" class="sb_title_text">
      {{ text }}
    </component>
    <div v-else class="sb_title_text">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: undefined,
    },
    type: {
      type: String,
      default: 'div',
    },
    size: {
      type: String,
      default: 'regular',
      validator: (value) =>
        ['xxsmall', 'xsmall', 'small', 'regular', 'large', 'xlarge'].includes(
          value,
        ),
    },
    addMargin: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style lang="scss">
.sb_title {
  &_text {
    font-weight: bold;
    line-height: 1;
  }

  //
  //  VERSION - ADD MARGIN
  //
  &.v_add-margin {
    margin: 0 0 1rem 0;
  }

  //
  //  SIZE - XXSMALL
  //
  &[size='xxsmall'] {
    .sb_title_text {
      @include fontSizeRegular;
    }
  }

  //
  //  SIZE - XSMALL
  //
  &[size='xsmall'] {
    .sb_title_text {
      @include fontSizeLarge;
    }
  }

  //
  //  SIZE - SMALL
  //
  &[size='small'] {
    .sb_title_text {
      @include h2Small();
    }
  }

  //
  //  SIZE - REGULAR
  //
  &[size='regular'] {
    .sb_title_text {
      @include h2Regular();
    }
  }

  //
  //  SIZE - LARGE
  //
  &[size='large'] {
    .sb_title_text {
      @include h1Regular();
    }
  }

  //
  //  SIZE - XLARGE
  //
  &[size='xlarge'] {
    .sb_title_text {
      @include h1Large();
    }
  }
}
</style>
