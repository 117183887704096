import Shepherd from 'shepherd.js';
import content from './content.json';

const defaultButtons = [
  {
    action() {
      return this.back();
    },
    classes: 'sb-shepherd-btn-secondary',
    text: 'Vorige',
  },
  {
    action() {
      return this.next();
    },
    text: 'Volgende',
    classes: 'sb-shepherd-btn-primary',
  },
];

function createContentElement(title, text) {
  const container = document.createElement('div');
  const titleEl = document.createElement('h3');
  const textEl = document.createElement('p');

  container.className = 'shepherd-content';
  titleEl.className = 'shepherd-title';
  textEl.className = 'shepherd-text';

  titleEl.innerText = title;
  textEl.innerText = text;

  container.appendChild(titleEl);
  container.appendChild(textEl);
  return container;
}

function getStep({ id, title, text, attachTo, buttons }) {
  return {
    id: id,
    title: '', // Keep empty to prevent the title from being shown
    text: createContentElement(title, text),
    attachTo: {
      element: attachTo?.element ?? '',
    },
    buttons: buttons ?? defaultButtons,
    cancelIcon: {
      enabled: true,
    },
    when: {
      show() {
        const currentStep = Shepherd.activeTour?.getCurrentStep();
        const currentStepElement = currentStep?.getElement();
        const header = currentStepElement?.querySelector('.shepherd-header');
        const progress = document.createElement('span');
        const progressLight = document.createElement('span');
        progress.className = 'sb-shepherd-progress';
        progressLight.innerText = `/${Shepherd.activeTour?.steps.length}`;

        progress.style['margin-right'] = '315px';
        progress.innerText = `${
          Shepherd.activeTour?.steps.indexOf(currentStep) + 1
        }`;
        progress.appendChild(progressLight);
        header?.insertBefore(
          progress,
          currentStepElement.querySelector('.shepherd-cancel-icon'),
        );
      },
    },
  };
}

export function createTour(key) {
  const tourContent = content[key];
  const tour = new Shepherd.Tour({
    name: key,
    useModalOverlay: true,
    defaultStepOptions: {
      classes: 'shepherd-theme-sb',
      scrollTo: false,
    },
    steps: tourContent.map((step, index) => {
      return {
        ...getStep(step),
        id: `${key}-step-${index}`,
        attachTo: {
          element: `${step.element}`,
          on: 'bottom',
        },
      };
    }),
  });

  return tour;
}
