<template>
  <div class="sb_spacer" :class="getHeight"></div>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: '20',
      validator: (value) =>
        [
          '5',
          '10',
          '16',
          '20',
          '30',
          '32',
          '40',
          '48',
          '50',
          '60',
          '64',
          '70',
          '80',
          '90',
          '96',
          '100',
          '120',
          '140',
          '160',
          '180',
          '200',
          '300',
          '400',
          '500',
        ].includes(value),
    },
  },
  computed: {
    getHeight() {
      return `v_height_${this.height}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.sb_spacer {
  width: 100%;

  //
  // HEIGHT VARIATIONS
  //
  $heights: [5 10 16 20 30 32 40 48 50 60 64 70 80 90 96 100 120 140 160 180 200
    300 400 500];

  @each $item in $heights {
    &.v_height_#{$item} {
      &:not([persist]) {
        height: rem-calc(math.div($item, 2));
        @include breakpoint($medium-portrait) {
          height: rem-calc($item);
        }
      }
      &[persist] {
        height: rem-calc($item);
      }
    }
  }
}
</style>
