import Vue from 'vue';

const key = '__mixin_log__';

if (!Vue[key]) {
  Vue[key] = true;
  Vue.mixin({
    methods: {
      log(...args) {
        console.log(...args);
      },
    },
  });
}
