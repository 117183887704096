<template>
  <svg role="img" :style="{ transform: `scale(${scale})` }">
    <use :xlink:href="'#' + iconId" />
  </svg>
</template>

<script>
export default {
  name: 'SbIcon',
  props: {
    iconId: {
      type: String,
      default: null,
    },
    scale: {
      type: String,
      default: '1.3',
    },
  },
};
</script>

<style lang="scss" scoped>
svg {
  width: 1em;
  height: 1em;
  fill: currentColor;
}
</style>
