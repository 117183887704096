const state = () => ({
  onboardingTours: {},
  tourQueue: [],
});

const getters = {
  getCurrentTourKey: (state) => {
    // Return the first tourKey from the tourQueue (if any).
    return state.tourQueue.length > 0 ? state.tourQueue[0] : null;
  },
  queueLength: (state) => {
    return state.tourQueue.length;
  },

  onboardingTours: (state) => {
    return state.onboardingTours;
  },
};

const actions = {
  startTour({ commit, state }) {
    if (state.tourQueue.length > 0) {
      const tourKey = state.tourQueue[0];
      commit('dequeueTour');
      return tourKey;
    }
    return null;
  },
  enqueueTour({ commit }, tourKey) {
    if (!tourKey) return;
    commit('enqueueTour', tourKey);
  },
  dequeueTour({ commit }) {
    commit('dequeueTour');
  },
  clearTourQueue({ commit }) {
    commit('clearTourQueue');
  },
  removeFromTourQueue({ commit }, tourKey) {
    commit('removeFromTourQueue', tourKey);
  },
  setOnboardingTours({ commit }, onboardingTours) {
    commit('setOnboardingTours', onboardingTours);
  },
};

const mutations = {
  enqueueTour(state, tourKey) {
    if (state.tourQueue.includes(tourKey)) return;
    state.tourQueue.push(tourKey);
  },
  dequeueTour(state) {
    state.tourQueue.shift();
  },
  clearTourQueue(state) {
    state.tourQueue = [];
  },
  removeFromTourQueue(state, tourKey) {
    state.tourQueue = state.tourQueue.filter((key) => key !== tourKey);
  },
  setOnboardingTours(state, onboardingTours) {
    state.onboardingTours = onboardingTours;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
