<template>
  <!-- eslint-disable max-len -->
  <div>
    <svg style="position: absolute; top: -9999px">
      <!--
        UI ICONS
        // UI icons are always fills, no lines
      -->
      <symbol id="icon_audio-fast" viewBox="0 0 500 500">
        <path
          d="M420.45,275.89c-4.93-4.13-10.82-7.04-17.59-8.74,8.72-13.91,5.61-33.85-9.33-43.81h0L142.66,56.37c-21.4-14.26-50.34,1.26-50.34,26.84V416.72c0,26.01,28.94,41.12,50.34,26.84l134.14-89.28h3.7v90.71c0,13.9,4.95,25.99,14.31,34.98,9.06,8.71,21.07,13.32,34.73,13.32s25.68-4.61,34.74-13.33c9.35-8.98,14.3-21.08,14.3-34.97v-90.71h8.94c13.64,0,24.8-3.66,33.19-10.88,6.55-5.64,14.37-16.07,14.37-33.71s-7.96-28.22-14.64-33.81Zm-16.69,47.81c-3.55,3.06-8.96,4.58-16.23,4.58h-34.94v116.71c0,6.78-2.11,12.18-6.32,16.23-4.21,4.05-9.79,6.07-16.73,6.07s-12.51-2.02-16.73-6.07c-4.21-4.05-6.32-9.45-6.32-16.23v-116.71h-34.94c-7.27,0-12.68-1.53-16.23-4.58-3.55-3.06-5.33-7.72-5.33-14s1.77-10.9,5.33-13.88c3.55-2.97,8.96-4.46,16.23-4.46h115.97c7.27,0,12.67,1.49,16.23,4.46,3.55,2.97,5.33,7.6,5.33,13.88s-1.78,10.94-5.33,14Z"
        />
      </symbol>

      <symbol id="icon_reading-comprehension" viewBox="0 0 24 24">
        <path
          d="M7.68,14.15c0,.48-.39,.88-.88,.88H1.74c-.48,0-.88-.39-.88-.88s.39-.88,.88-.88H6.81c.48,0,.88,.39,.88,.88ZM1.74,5.24H10.44c.48,0,.88-.39,.88-.88s-.39-.88-.88-.88H1.74c-.48,0-.88,.39-.88,.88s.39,.88,.88,.88Zm5.45,1.51H1.74c-.48,0-.88,.39-.88,.88s.39,.88,.88,.88H7.18c.48,0,.88-.39,.88-.88s-.39-.88-.88-.88ZM1.74,11.76H6.28c.48,0,.88-.39,.88-.88s-.39-.88-.88-.88H1.74c-.48,0-.88,.39-.88,.88s.39,.88,.88,.88Zm21.38,9.14c-.09,.51-.38,.96-.8,1.26-.33,.23-.72,.35-1.11,.35-.11,0-.23-.01-.34-.03-.51-.09-.96-.38-1.26-.8l-1.37-1.96c-.45-.65-.54-1.45-.28-2.17-.07-.06-.15-.11-.2-.19l-.32-.46c-.91,.46-1.88,.69-2.86,.69-1.99,0-3.96-.94-5.18-2.69-2-2.85-1.3-6.8,1.55-8.8,2.85-2,6.8-1.3,8.8,1.55,.97,1.38,1.34,3.06,1.04,4.72-.24,1.37-.92,2.59-1.92,3.52l.32,.46s.04,.1,.07,.15c.85-.07,1.67,.28,2.17,.99l1.37,1.96c.29,.42,.41,.95,.32,1.46Zm-4.06-8.83c.21-1.2-.06-2.41-.75-3.41-.7-1-1.74-1.66-2.94-1.87-.27-.05-.54-.07-.8-.07-.93,0-1.83,.28-2.6,.83h0c-1,.7-1.66,1.74-1.87,2.94s.06,2.41,.75,3.41c.7,1,1.74,1.66,2.94,1.88,1.2,.21,2.41-.06,3.41-.75,1-.7,1.66-1.74,1.88-2.94Zm-4.43,.99c-.21,0-.38,.07-.53,.22-.15,.15-.22,.32-.22,.52,0,.21,.07,.38,.22,.53,.15,.15,.32,.22,.53,.22s.37-.07,.52-.22c.15-.15,.22-.32,.23-.53,0-.2-.08-.38-.23-.52-.15-.14-.32-.22-.52-.22Zm1.23-4.86c-.32-.14-.68-.2-1.1-.2-.38,0-.72,.07-1.03,.2-.31,.13-.56,.34-.75,.61-.19,.27-.29,.62-.3,1.03h1.27c.01-.25,.1-.45,.26-.58,.16-.13,.34-.2,.55-.2s.39,.07,.54,.19c.15,.13,.22,.3,.22,.52,0,.2-.07,.38-.2,.52-.13,.14-.29,.28-.48,.4-.17,.11-.32,.23-.44,.36-.12,.13-.21,.31-.28,.52-.06,.22-.1,.51-.1,.87v.11h1.18v-.11c0-.3,.06-.54,.18-.71,.11-.17,.29-.33,.54-.48,.29-.17,.52-.38,.7-.62,.18-.24,.26-.55,.26-.91s-.09-.67-.27-.93c-.18-.26-.43-.45-.75-.59Z"
        />
      </symbol>

      <symbol id="icon_audio-slow" viewBox="0 0 500 500">
        <path
          d="M444.85,421.65l-.15-.22-.15-.22-15.36-22.05-.05-.07-.05-.07c-.16-.23-.32-.45-.48-.67,1.42-1.51,2.77-3.08,4.07-4.72,10.97-13.84,16.53-30.57,16.53-49.73,0-31.36-13.61-49.66-25.02-59.48-6.79-5.84-14.79-10.41-23.95-13.71,11.79-13.84,9.56-36.52-6.72-47.37h0L142.66,56.37c-5.61-3.74-11.74-5.43-17.73-5.43-16.86,0-32.61,13.4-32.61,32.27V416.72c0,19.11,15.64,32.34,32.42,32.34,6.05,0,12.25-1.72,17.92-5.5l107.62-71.63v72.07c0,17.51,7.45,28.63,13.7,34.87,6.22,6.23,17.34,13.68,34.85,13.68s28.74-7.55,34.94-13.89c5.87-5.99,12.84-16.49,13.34-32.8l17.24,25.2c4.87,7.15,11.04,12.72,18.32,16.53,7.21,3.78,15.02,5.69,23.21,5.69,11.7,0,22.63-3.85,31.61-11.13,10.45-8.48,16.19-20.36,16.19-33.45,0-9.17-2.97-18.28-8.83-27.06Zm-318.84-3.05c-.61,.41-.99,.46-1.28,.46-.02,0-2.42-.03-2.42-2.34V83.21c0-.57,.09-.94,.58-1.4,.55-.52,1.37-.87,2.03-.87,.26,0,.57,.05,1.11,.4l250.85,166.96c.67,.45,.8,1.25,.8,1.85,0,.23-.04,.99-.4,1.22l-.19,.12-.19,.12-19.16,12.75h-60.4c-17.3,0-28.03,7.11-34,13.07-5.96,5.96-13.07,16.7-13.07,34v24.46l-124.27,82.71Zm295.1,43.37c-4.38,3.55-9.46,5.33-15.24,5.33-3.96,0-7.68-.91-11.15-2.73s-6.44-4.54-8.92-8.18l-32.21-47.08c-2.32-3.3-4.75-5.74-7.31-7.31-2.56-1.57-5.58-2.35-9.04-2.35h-16.11v44.35c0,6.94-1.98,12.43-5.95,16.48-3.96,4.05-9.42,6.07-16.35,6.07s-12.43-2.02-16.48-6.07c-4.05-4.05-6.07-9.54-6.07-16.48v-132.57c0-6.77,1.82-11.98,5.45-15.61s8.83-5.45,15.61-5.45h62.2c4.36,0,8.48,.21,12.39,.6,15.07,1.5,26.85,5.88,35.31,13.16,10.65,9.17,15.98,22.43,15.98,39.77,0,13.22-3.64,24.41-10.9,33.58-7.27,9.17-17.51,15.32-30.73,18.46,5.45,.83,10.2,2.64,14.25,5.45,4.05,2.81,8.05,7.02,12.02,12.64l15.36,22.05c2.97,4.46,4.46,8.67,4.46,12.64,0,5.29-2.19,9.71-6.57,13.26Zm-40.51-116.59c0,7.1-2.19,12.23-6.57,15.36-4.38,3.14-11.61,4.71-21.68,4.71h-31.72v-40.64h31.72c10.07,0,17.3,1.61,21.68,4.83,4.38,3.22,6.57,8.47,6.57,15.74Z"
        />
      </symbol>

      <symbol id="icon_arrow-download" viewBox="0 0 500 500">
        <path
          d="M130,371a10.91,10.91,0,1,0,0,21.82H370A10.91,10.91,0,1,0,370,371Zm119.81-262a10.91,10.91,0,0,0-10.73,11.06V312l-79.56-79.56a10.91,10.91,0,1,0-15.42,15.44l97.22,97.22h0a10.91,10.91,0,0,0,17.32,0l0-.05,97.19-97.19a10.91,10.91,0,1,0-15.42-15.44L260.91,312V120.14h0a10.92,10.92,0,0,0-11.09-11.06Z"
        />
      </symbol>

      <symbol id="icon_arrow-down" viewBox="0 0 500 500">
        <path
          d="M399.09,302.07,250,457.08l-149.09-155,31.16-32.41,95.67,99.5V42.92h44.52V369.16l95.67-99.5Z"
        />
      </symbol>

      <symbol id="icon_arrow-left" viewBox="0 0 500 500">
        <path
          d="M197.93,399.09,42.92,250l155-149.09,32.41,31.16-99.5,95.67H457.08v44.52H130.84l99.5,95.67Z"
        />
      </symbol>

      <symbol id="icon_arrow-right" viewBox="0 0 500 500">
        <path
          d="M302.07,100.91,457.08,250l-155,149.09-32.41-31.16,99.5-95.67H42.92V227.74H369.16l-99.5-95.67Z"
        />
      </symbol>

      <symbol id="icon_arrow-up" viewBox="0 0 500 500">
        <path
          d="M100.91,197.93,250,42.92l149.09,155-31.16,32.41-95.67-99.5V457.08H227.74V130.84l-95.67,99.5Z"
        />
      </symbol>
      <symbol id="icon_caret-up" viewBox="0 0 500 500">
        <path
          d="M348.89,315.57a24.72,24.72,0,0,0,0-33.42L251.22,177.08,153.56,282.15a24.72,24.72,0,0,0,0,33.42,22.56,22.56,0,0,0,33.32,0L253,247l62.62,68.54A22.56,22.56,0,0,0,348.89,315.57Z"
        />
      </symbol>
      <symbol id="icon_caret-right" viewBox="0 0 500 500">
        <path
          d="M185.65,347.67a24.74,24.74,0,0,0,33.42,0L324.14,250,219.07,152.33a24.74,24.74,0,0,0-33.42,0,22.58,22.58,0,0,0,0,33.33l68.54,66.07-68.54,62.62A22.57,22.57,0,0,0,185.65,347.67Z"
        />
      </symbol>
      <symbol id="icon_caret-down" viewBox="0 0 500 500">
        <path
          d="M348.89,184.43a24.72,24.72,0,0,1,0,33.42L251.22,322.92,153.56,217.85a24.72,24.72,0,0,1,0-33.42,22.56,22.56,0,0,1,33.32,0L253,253l62.62-68.54A22.56,22.56,0,0,1,348.89,184.43Z"
        />
      </symbol>
      <symbol id="icon_double-caret-down" viewBox="0 0 500 500">
        <path
          d="M358.77,240.06l-108.78,108.78-108.78-108.78c-4.68-4.68-11.7-4.68-16.38,0s-4.68,11.7,0,16.38l116.96,116.96c2.34,2.34,5.85,3.51,8.19,3.51s5.85-1.17,8.19-3.51l116.96-116.96c4.68-4.68,4.68-11.7,0-16.38s-11.7-3.51-16.38,0h0Zm-116.96,21.05c2.34,2.34,5.85,3.51,8.19,3.51s5.85-1.17,8.19-3.51l116.96-116.96c4.68-4.68,4.68-11.7,0-16.38-4.68-4.68-11.7-4.68-16.38,0l-108.78,107.61-108.78-108.78c-4.68-4.68-11.7-4.68-16.38,0s-4.68,11.7,0,16.38l116.96,118.13Z"
        />
      </symbol>
      <symbol id="icon_caret-left" viewBox="0 0 500 500">
        <path
          d="M316.79,347.67a24.74,24.74,0,0,1-33.42,0L178.31,250l105.06-97.67a24.74,24.74,0,0,1,33.42,0,22.57,22.57,0,0,1,0,33.33l-68.53,66.07,68.53,62.62A22.56,22.56,0,0,1,316.79,347.67Z"
        />
      </symbol>
      <symbol id="icon_close" viewBox="0 0 500 500">
        <path
          d="M291.87,248.68l130,131.74a31.21,31.21,0,0,1,0,43.91,30.22,30.22,0,0,1-43.33,0l-130-131.74-130,131.74a30.22,30.22,0,0,1-43.33,0,31.21,31.21,0,0,1,0-43.91l130-131.74L77.6,119.38a31.21,31.21,0,0,1,0-43.91,30.25,30.25,0,0,1,43.34,0L251,207.21,381,75.47a30.25,30.25,0,0,1,43.34,0,31.21,31.21,0,0,1,0,43.91Z"
        />
      </symbol>

      <symbol id="icon_info" viewBox="0 0 20 20">
        <path
          d="M13.28 15.72H7.56V14.38C8.48 14.38 8.94 14.14 8.94 13.66V9.48C8.94 8.98 8.48 8.74 7.56 8.74V7.44H11.92V13.64C11.9 14.12 12.36 14.36 13.3 14.36L13.28 15.72ZM8.9 4.18C8.98 3.98 9.1 3.82 9.26 3.66C9.42 3.52 9.58 3.4 9.78 3.32C9.98 3.24 10.18 3.18 10.4 3.2C10.62 3.2 10.84 3.24 11.04 3.32C11.24 3.4 11.42 3.52 11.56 3.66C11.7 3.8 11.82 3.98 11.9 4.18C11.98 4.38 12.04 4.6 12.02 4.82C12.02 5.04 11.98 5.26 11.9 5.46C11.82 5.66 11.7 5.84 11.56 5.98C11.42 6.14 11.24 6.26 11.04 6.34C10.84 6.42 10.62 6.48 10.4 6.46C10.18 6.46 9.96 6.42 9.76 6.34C9.36 6.16 9.06 5.86 8.88 5.46C8.8 5.26 8.74 5.04 8.76 4.82C8.76 4.6 8.8 4.38 8.9 4.18ZM10 0C7.34 0 4.8 1.06 2.92 2.92C1.04 4.78 0 7.34 0 10C0 12.66 1.06 15.2 2.92 17.08C4.78 18.96 7.34 20 10 20C12.66 20 15.2 18.94 17.08 17.08C18.96 15.22 20 12.66 20 10C20 7.34 18.94 4.8 17.08 2.92C15.22 1.04 12.66 0 10 0Z"
        />
      </symbol>
      <symbol id="icon_minus" viewBox="0 0 500 500">
        <path
          d="M118.26,275.15a26.3,26.3,0,0,1,.92-52.6l262.56,2.3a26.3,26.3,0,1,1-.93,52.6Z"
        />
      </symbol>
      <symbol id="icon_plus" viewBox="0 0 500 500">
        <path
          d="M407.58,250.69a26.3,26.3,0,0,1-26.77,26.76l-102.66.7-.7,102.66a26.3,26.3,0,1,1-52.6.93L223,277l-104.75-1.84a26.3,26.3,0,0,1,.92-52.6l102.67-.7.7-102.66a26.3,26.3,0,1,1,52.6-.93L277,223l104.75,1.84A26.31,26.31,0,0,1,407.58,250.69Z"
        />
      </symbol>
      <symbol id="icon_more" viewBox="0 0 500 500">
        <path
          d="M314.72,71.22c0,35.59-28.88,64.06-65,64.06s-65-28.47-65-64.06,28.88-64.06,65-64.06S314.72,35.63,314.72,71.22Zm0,180.14c0-33.22-28.88-61.69-65-61.69s-65,28.47-65,61.69c0,35.59,28.88,64.06,65,64.06S314.72,287,314.72,251.36Zm0,177.22c0-35.59-28.87-64.06-65-64.06s-65,28.47-65,64.06,28.88,64.06,65,64.06S314.71,464.17,314.71,428.58Z"
        />
      </symbol>

      <symbol id="icon_reset" viewBox="0 0 24 24">
        <path
          d="M5.40001 10.7H3.90001C3.20001 10.7 2.90001 11.4 3.10001 11.8L5.80001 16.8C6.10001 17.2 6.70001 17.2 7.10001 16.6L9.80001 11.8C9.90001 11.2 9.70001 10.7 9.00001 10.7H7.60001C8.10001 8 10.4 6.2 13.1 6.2C16.2 6.2 18.7 8.7 18.7 11.8C18.7 14.9 16.5 17.4 13.1 17.4V19.6C17.6 19.6 21 16.1 21 11.7C21 7.3 17.5 4 13.1 4C9.20001 4 5.90001 6.8 5.40001 10.7Z"
        />
      </symbol>
      <symbol id="icon_graduated" viewBox="0 0 500 500">
        <path
          d="M447.47,153,252.42,97a10.57,10.57,0,0,0-5.24,0L52,153a9.62,9.62,0,0,0-6.88,9.21V188.8a9.53,9.53,0,0,0,6.55,9.1l80.51,26.34c0,.33-.11.54-.11.87V294.9a9.57,9.57,0,0,0,2.94,6.91c26.84,25.68,68.84,41,112.26,41s85.31-15.36,112.25-41a9.3,9.3,0,0,0,2.95-6.91v-69l17.78-5.81V297.2a30.87,30.87,0,0,0-10.58,52.45l-10.26,42a10,10,0,0,0,1.75,8.23,9.68,9.68,0,0,0,7.53,3.73H411a9,9,0,0,0,7.42-3.73,9.27,9.27,0,0,0,1.75-8.23l-10.26-42a30.85,30.85,0,0,0-10.69-52.45V213.92L447.8,198a9.55,9.55,0,0,0,6.54-9.1V162.24A9.62,9.62,0,0,0,447.47,153ZM343.4,290.73c-23.46,20.74-59,33-96.11,33s-72.66-12.29-96.11-33V230.49l95.56,31.38a10.08,10.08,0,0,0,5.89,0l90.66-29.73v58.59Zm55.41,93.59h-18.1l6.65-27.21a17.53,17.53,0,0,0,2.4.11c.76,0,1.64-.11,2.4-.11Zm-9.05-46.19a11.63,11.63,0,1,1,11.56-11.63A11.6,11.6,0,0,1,389.76,338.13Zm45.49-156.25-36,11.85V188a9.62,9.62,0,0,0-7.09-9.22L261.69,143.26a9.59,9.59,0,0,0-4.91,18.54l123.49,33.47V200L249.8,242.78,64.24,181.88v-12.4L249.8,116.16l185.56,53.32v12.4Z"
        />
      </symbol>
      <symbol id="icon_messages" viewBox="0 0 24 24">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
          />
        </svg>
      </symbol>
      <symbol id="icon_apm" viewBox="0 0 500 500">
        <path
          d="M404.4,407.25H382.83V380.07a132.78,132.78,0,0,0-68.36-116.15,16,16,0,0,1,0-27.86,132.8,132.8,0,0,0,68.3-117.28v-26H404.4a8,8,0,0,0,0-16H95.6a8,8,0,0,0,0,16h21.57v27.18a132.78,132.78,0,0,0,68.36,116.15,16,16,0,0,1-.05,27.86,132.8,132.8,0,0,0-68.3,117.28v26H95.6a8,8,0,0,0,0,16H404.4a8,8,0,0,0,0-16Zm-52.66,0a78.88,78.88,0,0,0-157.75,0H133.17V381.09a116.89,116.89,0,0,1,60.09-103.17,32,32,0,0,0,0-55.82,117,117,0,0,1-60.14-102.17V92.75H366.82v26.16a116.89,116.89,0,0,1-60.09,103.17,32,32,0,0,0,0,55.82,117,117,0,0,1,60.14,102.17v27.18ZM275.3,304.86a10.4,10.4,0,1,1-10.4-10.39A10.4,10.4,0,0,1,275.3,304.86Zm.56-37.39a14.58,14.58,0,1,1-14.58-14.58A14.58,14.58,0,0,1,275.86,267.47Zm23.65-61.29a106.79,106.79,0,0,1-19.34,14,48.77,48.77,0,0,0-14.66,12.68,12.88,12.88,0,0,1-12.31,4.89,62.16,62.16,0,0,1-33.66-17.55c-16.76-16.62-34.33-29.6-40.29-41.11a7.17,7.17,0,0,1,5.66-10.44c5.58-.56,12.44-.58,17.38,1.61,15.82,7,28.51,24.36,40.22,27.12,18.7,4.4,39,3.16,53.43,1.17A4.43,4.43,0,0,1,299.51,206.18Z"
        />
      </symbol>
      <symbol id="icon_skill" viewBox="0 0 500 500">
        <path
          d="M374.86,372,313.49,268.37A106.34,106.34,0,0,0,250.05,76.75h0a106.34,106.34,0,0,0-63.44,191.62L125.36,371.48h0A8,8,0,0,0,124,376v0a7.85,7.85,0,0,0,8,7.87h38.49l17.46,34.95h0a7.88,7.88,0,0,0,13.77.62L250,340l48.33,79.5h0a7.89,7.89,0,0,0,13.79-.62l17.45-34.94h38.5v0A7.86,7.86,0,0,0,374.86,372ZM159.53,183.08a90.52,90.52,0,1,1,90.52,90.58A90.44,90.44,0,0,1,159.53,183.08ZM324.82,368.16a7.92,7.92,0,0,0-7,4.31l-13.29,26.58-47.71-78.4v0a7.86,7.86,0,0,0-13.52,0l-47.71,78.39-13.29-26.58h0a7.88,7.88,0,0,0-7-4.31H145.6l54.28-91.44a105.67,105.67,0,0,0,100.34,0l54.11,91.44Zm-23.3-228.93C281.92,162.88,262,196.05,247,228a5,5,0,0,1-8.87.35c-10.77-19.18-23.5-40.77-38.48-48.22a2,2,0,0,1-.4-3.35l10.25-8.48a5,5,0,0,1,6.83.42c10.07,10.73,17.26,21.69,24.91,34.95a374.71,374.71,0,0,1,47.63-69.29,5,5,0,0,1,5.93-1.21l6,2.84A2.1,2.1,0,0,1,301.52,139.23Z"
        />
      </symbol>
      <symbol id="icon_activity" viewBox="0 0 500 500">
        <path
          d="M186,250a64.09,64.09,0,0,0,63.18,64H250a64.16,64.16,0,0,0,64-64,66.12,66.12,0,0,0-2.13-16.55,8.89,8.89,0,0,0-14.64-4.31,18.67,18.67,0,0,1-26.38-26.35,8.89,8.89,0,0,0-4.3-14.67A67.2,67.2,0,0,0,250,186,64.09,64.09,0,0,0,186,250Zm72.72-8.69A36.27,36.27,0,0,0,296.24,250a46.24,46.24,0,1,1-46.77-46.26h.5A36.26,36.26,0,0,0,258.7,241.28Zm190.68,4.12-.38-.55h0c-6.39-8.7-76-99.25-199-99.25-126.49,0-196.45,95.71-199.37,99.79a7.88,7.88,0,0,0,0,9.18,241.25,241.25,0,0,0,42.81,43.16,7.89,7.89,0,0,0,11.18-1.16,7.86,7.86,0,0,0-1.31-11.14A248,248,0,0,1,67.1,250a254.69,254.69,0,0,1,112.78-77.23A104.36,104.36,0,0,0,250,354.39H250a104.36,104.36,0,0,0,70.2-181.58A255.69,255.69,0,0,1,432.91,250a247.24,247.24,0,0,1-36.23,35.43,7.89,7.89,0,1,0,9.85,12.33,241.19,241.19,0,0,0,42.83-43.19A7.92,7.92,0,0,0,449.38,245.4Zm-136.78-58a88.12,88.12,0,0,1,26,62.6,89.19,89.19,0,0,1-25.94,62.64A88,88,0,0,1,250,338.55a88.58,88.58,0,0,1,0-177.16A88.17,88.17,0,0,1,312.6,187.37Z"
        />
      </symbol>
      <symbol id="icon_stars" viewBox="0 0 500 500">
        <path
          d="M309.27,269.83a13.06,13.06,0,0,0,5.55-12.18l-6.11-52.88,35.37-41.29h0a13.13,13.13,0,0,0,2.41-12.61,13,13,0,0,0-9.53-8.55l-52.46-11.59L261.14,84v0a12.93,12.93,0,0,0-23.16,0L214.59,130.7l-52.45,11.6v0a13,13,0,0,0-7.09,21.16l35.36,41.29-6.1,52.88a13,13,0,0,0,18.92,13l46.34-24,46.35,24A13,13,0,0,0,309.27,269.83Zm-67.39-37.46-39.82,20.72,5.05-44.89h0a16.89,16.89,0,0,0-4-13l-32.64-38L219.3,146.3a17.09,17.09,0,0,0,11.42-8.76l18.86-37.72v0l18.86,37.72,0,0a17,17,0,0,0,11.42,8.76l48.84,10.89-32.64,38a17,17,0,0,0-4,13l5.05,44.89-39.82-20.72A17,17,0,0,0,241.88,232.37Zm214.05,90.52a10.76,10.76,0,0,0-7.89-7.07l-43.41-9.59-19.36-38.72v0a10.71,10.71,0,0,0-19.17,0l-19.36,38.72-43.41,9.59v0a10.76,10.76,0,0,0-5.87,17.51l29.27,34.17-5.05,43.77a10.78,10.78,0,0,0,15.66,10.78l38.36-19.87,38.36,19.87a10.76,10.76,0,0,0,15.63-10.78l-5.05-43.77,29.27-34.17h0A10.85,10.85,0,0,0,455.93,322.89Zm-44,36.13a13.27,13.27,0,0,0-3.11,10.15l3.94,35L381.71,388a13.26,13.26,0,0,0-12,0l-31,16.16,3.93-35h0A13.15,13.15,0,0,0,339.45,359L314,329.4l38.09-8.5a13.3,13.3,0,0,0,8.9-6.83l14.71-29.42v0L390.41,314l0,0a13.34,13.34,0,0,0,8.91,6.83l38.09,8.5Zm-215.26-43.2-43.41-9.59L133.9,267.51v0a10.71,10.71,0,0,0-19.17,0L95.37,306.21,52,315.8v0a10.76,10.76,0,0,0-5.87,17.51L75.36,367.5l-5,43.77A10.77,10.77,0,0,0,86,422.05l38.36-19.87,38.36,19.87a10.77,10.77,0,0,0,15.64-10.78l-5-43.77,29.27-34.17h0a10.78,10.78,0,0,0-5.89-17.51ZM160.55,359a13.27,13.27,0,0,0-3.11,10.15l3.94,35-31-16.16a13.24,13.24,0,0,0-12,0l-31,16.16,3.94-35h0A13.15,13.15,0,0,0,88.07,359L62.62,329.4l38.09-8.5a13.34,13.34,0,0,0,8.91-6.83l14.71-29.42v0L139,314l0,0a13.3,13.3,0,0,0,8.9,6.83l38.1,8.5Z"
        />
      </symbol>
      <symbol id="icon_show" viewBox="0 0 500 500">
        <path
          d="M253.17,298.33c-28,1.89-50.67-22.27-49.32-51.65,1.36-24.16,19.91-43.59,43-45,28-1.89,50.67,22.27,49.32,51.65C294.79,277,275.79,296.91,253.17,298.33Zm2.26,41.22c-51.58,3.31-94.55-41.22-91.39-95.71,2.72-44.54,37.55-81,80.08-83.86,51.57-3.32,94.55,41.22,91.38,95.71C332.79,300.7,298.41,336.7,255.43,339.55Zm-5.43-217c-77.81,0-148.84,44.54-200.87,117.5a18.52,18.52,0,0,0,0,20.37c52,72.49,123.06,117,200.87,117S398.84,332.91,450.87,260a18.52,18.52,0,0,0,0-20.37C398.84,167.09,327.81,122.55,250,122.55Z"
        />
      </symbol>
      <symbol id="icon_hide" viewBox="0 0 500 500">
        <path
          d="M78,229.5c-7.49-7.09-15-14.18-22-22.28-3.28-4.05-6.56-7.59-9.37-11.64l-.94-1.52-2.34-2.53a57.45,57.45,0,0,1-3.75-9.62,44,44,0,0,1-.47-8.1c.47-6.07,2.81-12.15,7-16.2a30.92,30.92,0,0,1,6.09-5.06,19.61,19.61,0,0,1,7-2.53,8.47,8.47,0,0,1,3.75-.51h1.87a9.52,9.52,0,0,1,3.75.51,26.4,26.4,0,0,1,11.72,6.07c1.87,2,3.28,4,5.15,6.08a9.14,9.14,0,0,0,2.34,3.54c2.82,3,5.16,6.08,8,9.11a214.39,214.39,0,0,0,37.49,32.4c28.58,20.25,61.38,33.92,95.58,38,5.16.51,10.78,1,16.4,1,7,0,14.06,0,21.09-.51,37-3.54,72.16-17.72,103.09-40a218.29,218.29,0,0,0,38.42-34.94c2.81-3,5.15-6.07,7.49-9.11l.94-1,.94-1,1.41-1.52c.47-.51.93-1,1.4-1.52.94-1,1.88-1.52,2.81-2.53a19.28,19.28,0,0,1,12.18-4.56,30.37,30.37,0,0,1,9.38,1.52c2.34,1,4.68,2,6.56,4.05,2.81,2.53,5.62,5.57,7,9.11a25.1,25.1,0,0,1,2.81,11.65,47.68,47.68,0,0,1-.94,8.1,31.77,31.77,0,0,1-3.28,7.08c-.47,1-1.41,2-1.87,3a25.35,25.35,0,0,1-3.28,4.05c-5.63,7.09-11.72,13.67-17.81,20.25-3.75,3.55-7.5,7.6-11.25,11.14L443.05,268c7,13.17,3.75,29.87-7.5,38.48a26.92,26.92,0,0,1-22,5.06,28.26,28.26,0,0,1-18.27-14.17c-6.09-11.14-12.66-23.29-18.28-33.92-7,4.05-13.59,7.59-20.61,11.14l13.12,52.14c4.21,15.69-3.75,31.9-17.81,37a24.92,24.92,0,0,1-21.55-2,27.75,27.75,0,0,1-13.59-18.22c-4.69-17.22-10.78-38-14.06-50.12-6.09,1.52-12.18,2.53-18.27,3.54-2.35.51-4.69.51-6.56,1v62.78c0,16.71-12.19,29.87-27.65,29.87s-27.65-13.16-27.65-29.87V297.84c-2.34-.5-4.68-.5-6.56-1-6.09-1-12.18-2-18.27-3.54l-14.06,50.12a32.35,32.35,0,0,1-13.59,18.22,26.76,26.76,0,0,1-21.55,2c-14.06-5.06-22-21.27-17.81-37,5.16-18.22,11.25-40.5,14.53-52.14-7-3.55-14.06-7.09-20.62-11.14l-18.27,33.92a26.45,26.45,0,0,1-18.28,14.17,24.91,24.91,0,0,1-22-5.06c-11.24-8.61-15-25.82-7.5-39C63.51,256.33,71.94,241.14,78,229.5Z"
        />
      </symbol>
      <symbol id="icon_check" viewBox="0 0 500 500">
        <path
          d="M47,265.24,90.75,209l116.92,96.43L399.81,94.76l53.2,47.7L215.13,405.24Z"
        />
      </symbol>
      <symbol id="icon_cross" viewBox="0 0 500 500">
        <path
          d="M88.85,134.22l45.37-45.37L250.34,204.29,365.78,88.85l45.37,45.37L295.71,250.34,411.15,365.78l-45.37,45.37L250.34,295.71,134.22,411.15,88.85,365.78,204.29,250.34Z"
        />
      </symbol>
      <symbol id="icon_dash" viewBox="0 0 500 500">
        <path d="M110.1,217.82H389.9v64.36H110.1Z" />
      </symbol>
      <symbol id="icon_cog" viewBox="0 0 500 500">
        <path
          d="M250.5,325.56a75.87,75.87,0,1,0-75.86-75.87A75.94,75.94,0,0,0,250.5,325.56Zm0-113.8c20.56,0,37.93,17.37,37.93,37.93s-17.37,37.93-37.93,37.93-37.93-17.37-37.93-37.93S229.94,211.76,250.5,211.76Z"
        />
        <path
          d="M76.84,328.14,95.8,361c10.07,17.39,34.31,23.91,51.78,13.84l10-5.8a154,154,0,0,0,36,21.09v11.34a38,38,0,0,0,37.93,37.93h37.93a38,38,0,0,0,37.94-37.93V390.08A154.31,154.31,0,0,0,343.32,369l10,5.8c17.5,10.05,41.68,3.57,51.79-13.86l19-32.8a38,38,0,0,0-13.87-51.81l-9.57-5.54a146.12,146.12,0,0,0,0-42.18l9.57-5.54a38,38,0,0,0,13.87-51.81l-19-32.79c-10.07-17.45-34.29-24-51.79-13.88l-10,5.8a153.35,153.35,0,0,0-35.95-21.09V98A38,38,0,0,0,269.43,60H231.5A38,38,0,0,0,193.57,98v11.34a154.23,154.23,0,0,0-36,21.07l-10-5.8c-17.52-10.07-41.72-3.55-51.79,13.88l-19,32.79A38,38,0,0,0,90.7,223.07l9.58,5.53a145,145,0,0,0,0,42.16L90.7,276.3A38,38,0,0,0,76.84,328.14Zm63.08-52.31a107.35,107.35,0,0,1,0-52.27,19,19,0,0,0-8.91-21l-21.3-12.32,18.92-32.79L150.33,170a18.91,18.91,0,0,0,22.53-2.69,115.27,115.27,0,0,1,45.22-26.54,18.94,18.94,0,0,0,13.42-18.13V98h37.93v24.65a19,19,0,0,0,13.43,18.13,115.59,115.59,0,0,1,45.22,26.54A19,19,0,0,0,350.61,170l21.69-12.54,19,32.8L370,202.54a19,19,0,0,0-8.91,21,107.35,107.35,0,0,1,0,52.27,19,19,0,0,0,8.93,21l21.3,12.31-18.93,32.79-21.71-12.53a18.87,18.87,0,0,0-22.53,2.69,115.2,115.2,0,0,1-45.22,26.53,19,19,0,0,0-13.43,18.13l0,24.66h-38V376.76a19,19,0,0,0-13.42-18.13,115.37,115.37,0,0,1-45.22-26.53,18.94,18.94,0,0,0-22.53-2.68L128.63,342l-19-32.79L131,296.84A19,19,0,0,0,139.92,275.83Z"
        />
      </symbol>

      <symbol id="icon_print" viewBox="0 0 24 24">
        <path
          d="M19 7H18V2H6V7H5C3.346 7 2 8.346 2 10V17C2 18.103 2.897 19 4 19H6V22H18V19H20C21.103 19 22 18.103 22 17V10C22 8.346 20.654 7 19 7ZM8 4H16V7H8V4ZM16 20H8V16H16V20ZM20 17H18V16V14H6V16V17H4V10C4 9.449 4.449 9 5 9H19C19.552 9 20 9.449 20 10V17Z"
        />
      </symbol>

      <symbol id="icon_pencil" viewBox="0 0 13 13">
        <path
          d="M11.6353 1.40831L10.7268 0.502546C10.4427 0.219231 10.0619 0.0368681 9.68071 0.00244237C9.63872 -0.00127933 9.59859 0.000116303 9.56313 0.00104673L9.48847 0.00290758C9.14645 0.0177944 8.8427 0.148054 8.60753 0.382521L0.7472 8.21949C0.56336 8.39302 0.49477 8.55491 0.415448 8.74239L0.403782 8.76984C0.311396 8.98849 0.229741 9.24249 0.161151 9.52395C-0.0105575 10.2329 -0.0460193 10.9284 0.0594321 11.5337C0.0995597 11.7886 0.306263 11.9984 0.56196 12.044C0.791527 12.0835 1.03369 12.1036 1.28472 12.1036C1.69906 12.1036 2.1372 12.0496 2.58 11.9431C2.84316 11.8793 3.10726 11.7933 3.34336 11.6942L3.40075 11.6704C3.57712 11.5969 3.7297 11.5337 3.89208 11.3625L11.7561 3.52084C12.3067 2.97189 12.2526 2.02426 11.6353 1.40831ZM3.03074 10.4646C3.02607 10.4674 3.02094 10.4707 3.01581 10.4744C2.98268 10.4953 2.92809 10.5307 2.86136 10.5586C2.70412 10.6246 2.49555 10.6916 2.29024 10.7414C1.98742 10.8144 1.62674 10.8498 1.25859 10.8414C1.25113 10.4739 1.28612 10.1134 1.35891 9.81284C1.42097 9.5593 1.49283 9.36717 1.54228 9.24994C1.56841 9.18806 1.60201 9.13317 1.62394 9.09688C1.62954 9.08758 1.63467 9.0792 1.63934 9.0713L8.11294 2.6174L9.5076 4.00792L3.03074 10.4646ZM10.8789 2.64671L10.3839 3.1403L8.98221 1.7428L9.47587 1.25061C9.48054 1.24689 9.50434 1.23572 9.54866 1.23339H9.55053C9.56546 1.232 9.58459 1.2306 9.59719 1.23246C9.68258 1.2427 9.76983 1.29062 9.84962 1.37017C10.0101 1.52974 10.1678 1.6893 10.326 1.84841C10.4693 1.99309 10.6125 2.13777 10.7581 2.28291C10.9401 2.46481 10.9046 2.62066 10.8789 2.64671Z"
          fill="black"
        />
      </symbol>

      <symbol id="icon_signout">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
          border="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
          />
        </svg>
      </symbol>

      <symbol id="icon_refresh">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-6 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
          />
        </svg>
      </symbol>

      <symbol id="icon_bin">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
          />
        </svg>
      </symbol>

      <symbol id="icon_clock" viewBox="0 0 24 24">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke-width="1.5"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
          />
        </svg>
      </symbol>

      <symbol id="icon_user" viewBox="0 0 24 24">
        <svg stroke-width="1.5" stroke="currentColor">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
          />
        </svg>
      </symbol>

      <!-- eslint-disable max-len -->
    </svg>
  </div>
</template>

<script>
export default {
  name: 'SvgSymbols',
};
</script>

<style lang="scss" scoped></style>
